import React from 'react'
import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const BodyStyled = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const BodyInner = styled.div`
  max-width: 1600px;
  padding: 24px;
`

export const Body: React.FC = ({ children }) => (
  <BodyStyled>
    <BodyInner>{children}</BodyInner>
  </BodyStyled>
)

export const Footer = styled.div`
  flex-grow: 0;
  background: ${({ theme }) => theme.color.onPrimaryLight};
`

export const ButtonsFooter = styled.div`
  border-top: 1px solid #e6e6e6;
  flex-grow: 0;
  flex-shrink: 0;
  height: 72px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.onPrimaryLight};

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`
export const ActionButtonsWrapper = styled.div`
  &:not(:empty) {
    padding-left: 20px;
    position: relative;
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: column;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-right: 1px solid ${props => props.theme.color.outlineNeutral};
      height: 30px;
      transform: translateY(-50%);
    }
  }
`

const appear = keyframes`
  70% {
    transform: translateX(-50px);
  }

  100% {
    transform: translateX(0);
  }
`
export const NotificationWarning = styled.div`
  background: ${({ theme }) => theme.color.onPrimaryLight};
  color: ${({ theme }) => theme.color.accentNotify};
  border-radius: 16px;
  padding: 20px 24px 24px;
  position: fixed;
  bottom: 96px;
  right: 24px;
  z-index: 100;
  max-width: 320px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  transform: translateX(calc(100% + 96px));
  animation: ${appear} 0.3s ease-out;
  animation-fill-mode: forwards;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);

  button {
    margin-top: 24px;
  }
`
