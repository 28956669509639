import React, { useCallback } from 'react'
import NoteDetailsForm, { FormData } from './NoteDetailsForm/NoteDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useNote } from 'modules/domain/note/hooks'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import NoteSelectors from 'modules/domain/note/selectors'
import NoteActions from 'modules/domain/note/duck'
import { NoteType } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import useDateFormatFn from 'hooks/useDateFormatFn'
import ContactRoutes from 'views/pages/Contact/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import FarmerRoutes from 'views/pages/Farmer/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { zip } from 'ramda'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { useCompanyById } from 'modules/domain/company/hooks'
import { useContact } from 'modules/domain/contact/hooks'
import { useFarmer } from 'modules/domain/farmer/hooks'

const NoteId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const HeaderInner = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 36px;
`

const NoteEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const push = useHistoryPush()
  const updateNoteAction = useAction(NoteActions.updateRequested)
  const removeNoteAction = useAction(NoteActions.removeRequested)
  const page = useSelector(NoteSelectors.page)
  const { t } = useTranslation('note')
  const getFormatedDate = useDateFormatFn({ withTime: true })

  const handleSubmit = useCallback(
    (data: FormData) => {
      updateNoteAction(params.id, {
        parent_id: data.parent_id,
        note_type: data.note_type,
        note_title: data.note_title,
        note_text: data.note_text,
        created_by: data.created_by,
      })
    },
    [params.id, updateNoteAction],
  )

  const handleRemove = () => {
    removeNoteAction(params.id)
    goBack()
  }

  const [fetchProgress, note] = useNote(params.id)

  const [contactFetchProgress, contact] = useContact(note ? note.parent_id : '')
  const [companyFetchProgress, company] = useCompanyById(note ? note.parent_id : '')
  const [farmerFetchProgress, farmer] = useFarmer(note ? note.parent_id : '')

  const meta = useSelector(state => NoteSelectors.meta(state, params.id))

  const returnRoute = (note_type: NoteType) => {
    switch (note_type) {
      case NoteType.ContactNote:
        return ContactRoutes.Edit
      case NoteType.CompanyNote:
        return CompanyRoutes.Edit
      case NoteType.FarmerNote:
        return FarmerRoutes.Edit
      default:
        return DashboardRoutes.Dashboard
    }
  }

  const goBack = () =>
    note
      ? history.push(generatePath(returnRoute(note.note_type), { id: note.parent_id }))
      : push({ route: DashboardRoutes.Dashboard, query: { page } })

  useHelmet({ title: t('notesMetaTitle') })

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !note) {
    return loading()
  }

  const name = ['Note', note.created_by, getFormatedDate(note.created_at)].filter(Boolean).join(' - ')

  const idString = zip(['ID'], [note.id])
    .filter(([, id]) => !!id)
    .map(arr => arr.join(': '))
    .join(', ')

  const getParentTypeDisplayString = (note_type: NoteType) => {
    switch (note_type) {
      case NoteType.ContactNote:
        return zip(
          ['CONTACT'],
          [contactFetchProgress !== Progress.ERROR ? contact?.first_name + ' ' + contact?.last_name : ''],
        )
      case NoteType.CompanyNote:
        return zip(['COMPANY'], [companyFetchProgress !== Progress.ERROR ? company?.official_name : ''])
      case NoteType.FarmerNote:
        return zip(
          ['FARMER'],
          [farmerFetchProgress !== Progress.ERROR ? farmer?.first_name + ' ' + farmer?.last_name : ''],
        )
      default:
        return zip(['PARENT ID'], [note.parent_id])
    }
  }

  const parentIdString = getParentTypeDisplayString(note.note_type)
    .filter(([, id]) => !!id)
    .map(arr => arr.join(': '))
    .join(', ')

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <HeaderInner>
            <div>
              <Header.Title size={'small'} compact={true} title={name} />
              <NoteId>{idString}</NoteId>
              <NoteId>{parentIdString}</NoteId>
            </div>
          </HeaderInner>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <NoteDetailsForm
          onCancel={goBack}
          onRemove={handleRemove}
          mode={'edit'}
          progress={meta.updateProgress}
          initialValues={{
            parent_id: note.parent_id,
            note_type: note.note_type,
            created_by: note.created_by,
            note_title: note.note_title,
            note_text: note.note_text,
          }}
          onSubmit={handleSubmit}
        />
      </Layout.Content>
    </>
  )
}

export default NoteEdit
