import { useAction, usePersistentScroll } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import AuthSelectors from 'modules/domain/auth/selectors'
import { FarmerTargetSkuActions } from 'modules/domain/target2sku/farmerTarget/duck'
import { useFarmerTargetSkuList } from 'modules/domain/target2sku/farmerTarget/hooks'
import FarmerTargetSkuSelectors from 'modules/domain/target2sku/farmerTarget/selectors'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isDistributor, isProducer } from 'types/entities'
import { CompoundedTableEditable } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditable'
import { FarmerTargetSkuForm } from '../forms/FarmerTargetSkuForm'
import { TextTertiary } from '../styles'
import { BolderCell, TertiaryCell, TitleCell, TitleI18nCell } from './common'
import useCompareStrings from 'hooks/useCompareStrings'

export const FarmerTargetSkuTable: React.VFC = () => {
  const { t } = useTranslation('target2')
  const { pick } = useLangPicker()

  const [progress, data = []] = useFarmerTargetSkuList()

  const total = useSelector(FarmerTargetSkuSelectors.total)
  const page = useSelector(FarmerTargetSkuSelectors.page)
  const pages = useSelector(FarmerTargetSkuSelectors.pages)
  const pageSize = useSelector(FarmerTargetSkuSelectors.pageSize)
  const targetsTotal = useSelector(FarmerTargetSkuSelectors.targetsTotal)
  const farmerOrdersTotal = useSelector(FarmerTargetSkuSelectors.farmerOrdersTotal)
  const previousYear = useSelector(FarmerTargetSkuSelectors.previousYear)

  const listRequested = useAction(FarmerTargetSkuActions.listRequested)
  const { scrollRef } = usePersistentScroll('farmerTargetSkuList')

  const userCompany = useSelector(AuthSelectors.userCompany)
  const role = useSelector(AuthSelectors.role)
  const producer = isProducer(role)
  const distributor = isDistributor(role)
  const hideManufacturerColumn = userCompany
    ? producer
      ? true
      : distributor && userCompany.producers_relations.length === 1
      ? false
      : true
    : false

  const hideRetailerColumn = distributor && !userCompany?.has_branch_company
  const compareStrings = useCompareStrings()

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.manufacturer'),
        accessor: 'manufacturer',
        width: 217,
        hidden: hideManufacturerColumn,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'territory',
        width: 230,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season',
        width: 217,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'subcategory',
        width: 160,
        Cell: TitleI18nCell(pick),
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product',
        width: 217,
        Cell: TitleI18nCell(pick),
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.retailer'),
        accessor: 'retailer' as const,
        width: 213,
        hidden: hideRetailerColumn,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.farmer'),
        accessor: 'farmer' as const,
        width: 156,
        Cell: ({ value }) => {
          return value?.first_name && value?.last_name ? `${value?.first_name} ${value?.last_name}` : ''
        },
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.farmerTarget'),
        accessor: 'target_value' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
        Cell: BolderCell,
      },
      {
        Header: t('list.tableHeaders.farmerOrders'),
        accessor: 'farmer_orders_sku' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: <TextTertiary>{t('list.tableHeaders.farmerPreviousYear')}</TextTertiary>,
        accessor: 'farmer_orders_sku_previous_year' as const,
        width: 76,
        Cell: TertiaryCell,
        headerAlign: 'right',
        cellAlign: 'right',
      },
    ],
    [t, hideManufacturerColumn, compareStrings, pick, hideRetailerColumn],
  )

  const metaColumns = useMemo(
    () => ({
      farmer_orders_sku: farmerOrdersTotal,
      target_value: targetsTotal,
      farmer_orders_sku_previous_year: <TextTertiary>{previousYear}</TextTertiary>,
    }),
    [farmerOrdersTotal, previousYear, targetsTotal],
  )

  const canEdit = usePermissions({ capability: CAPABILITY.FARMER_TARGETS, permission: PERMISSION.CRU })

  return (
    <CompoundedTableEditable
      FormComponent={FarmerTargetSkuForm}
      columns={columns}
      metaColumns={metaColumns}
      data={data}
      ref={scrollRef}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={page => listRequested({ page })}
      pages={pages}
      readonly={!canEdit}
      newTitle={t('form.new')}
      isSortable
      defaultSortField="territory"
    />
  )
}

export default FarmerTargetSkuTable
