import React, { useCallback, useContext, useEffect } from 'react'
import { Redirect, Route as ReactRouterRoute, RouteProps, Switch } from 'react-router-dom'

import AuthSelectors from 'modules/domain/auth/selectors'
import AuthActions from 'modules/domain/auth/duck'

import AuthPage from 'views/pages/Auth/AuthPage'
import CompanyPage from 'views/pages/Company/CompanyPage'
import UserPage from 'views/pages/User/UserPage'
import BadgePage from 'views/pages/Badge/BadgePage'
import FarmerOrderSkuPage from 'views/pages/FarmerOrderSku/FarmerOrderSkuPage'
import CategoryPage from 'views/pages/Categories/CategoryPage/CategoryPage'

import UpTakePage from 'views/pages/UpTake/UpTakePage'
import TerritoryPage from 'views/pages/Territory/TerritoryPage'
import ReportPage from 'views/pages/Reports/ReportPage'
import PromoInfoPage from './pages/PromoInfo/PromoInfoPage'
import DocumentPage from 'views/pages/Document/DocumentPage'
import PromocodePage from 'views/pages/Promocode/PromocodePage'
import FarmerPage from './pages/Farmer/FarmerPage'
import HouseholdPage from 'views/pages/Household/HouseholdPage'
import LicensePage from 'views/pages/License/LicensePage'
import ReconciliationPage from 'views/pages/Reconciliation/ReconciliationPage'
import SeasonPage from 'views/pages/Season/SeasonPage'
import InventoryExcnahgePage from 'views/pages/InventoryExchange/InventoryPage'
import StorefrontPage from 'views/pages/Storefront/StorefrontPage'
import ContactPage from 'views/pages/Contact/ContactPage'
import NotePage from 'views/pages/Note/NotePage'

import UserRoutes from 'views/pages/User/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import BadgeRoutes from 'views/pages/Badge/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import CategoryRoutes from 'views/pages/Categories/CategoryPage/routes'
import TerritoryRoutes from 'views/pages/Territory/routes'
import ReportRoutes from 'views/pages/Reports/routes'
import UpTakeRoutes from 'views/pages/UpTake/routes'
import FarmerRoutes from './pages/Farmer/routes'
import PromocodeRoutes from 'views/pages/Promocode/routes'
import PromoInfoRoutes from './pages/PromoInfo/routes'
import DocumentRoutes from 'views/pages/Document/routes'
import HouseholdRoutes from 'views/pages/Household/routes'
import LicenseRoutes from 'views/pages/License/routes'
import ReconciliationRoutes from 'views/pages/Reconciliation/routes'
import SeasonRoutes from 'views/pages/Season/routes'
import InventoryExchangeRoutes from 'views/pages/InventoryExchange/routes'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import ContactRoutes from './pages/Contact/routes'
import NoteRoutes from './pages/Note/routes'

import Dashboard from './pages/Dashboard/Dashboard'
import { Helmet } from 'react-helmet'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import { useSelector } from 'react-redux'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { HelmetContext, Modal, Snackbar, useAction, useHelmet, useZendeskWidget } from '@agro-club/frontend-shared'
import DiscountRuleRoutes from './pages/DiscountRule/routes'
import DiscountRulePage from './pages/DiscountRule/DiscountRulePage'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCrmSectionAccessString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, isAgro, Sections } from 'types/entities'
import { useConfig } from 'modules/domain/config/hooks'
import styled from 'styled-components'
import FarmerPlansRoutes from './pages/FarmerPlans/routes'
import SalesTeamPlansRoutes from './pages/SalesTeamPlans/routes'
import MarketingCampaignsRoutes from './pages/MarketingCampaigns/routes'
import FarmerPlansPage from './pages/FarmerPlans/FarmerPlansPage'
import SalesTeamPlansPage from './pages/SalesTeamPlans/SalesTeamPlansPage'
import MarketingCampaignsPage from './pages/MarketingCampaigns/MarketingCapmaignsPage'
import FarmerOrderSkuRoutes from './pages/FarmerOrderSku/routes'
import DistributorOrderSkuPage from './pages/DistributorOrderSku/DistributorOrderSkuPage'
import DistributorOrderSkuRoutes from './pages/DistributorOrderSku/routes'
import useLanguage from 'hooks/useLanguage'
import env from 'env'
import IncentiveCampaignRoutes from './pages/IncentiveCampaign/IncentiveCampaign/routes'
import IncentiveCampaignPage from './pages/IncentiveCampaign/IncentiveCampaign/IncentiveCampaignPage'
import IncentiveProgramRoutes from './pages/IncentiveCampaign/IncentiveProgram/routes'
import IncentiveProgramPage from './pages/IncentiveCampaign/IncentiveProgram/IncentiveProgramPage'
import useWLFeatureFlags from 'hooks/featureFlags/useWLFeatureFlags'
import NotificationsRoutes from './pages/NotificationsList/routes'
import NotificationsPage from './pages/NotificationsList/NotificationsListPage'
import ProgramSummaryRoutes from './pages/ProgramSummary/routes'
import ProgramSummaryPage from './pages/ProgramSummary/ProgramSummaryPage'
import AllocationPage from './pages/Allocation/AllocationPage'
import AllocationRoutes from './pages/Allocation/routes'
import ProductsAvailabilityPage from '../views/pages/ProductsAvailability/ProductsAvailabilityPage'
import ProductsAvailabilityRoutes from './pages/ProductsAvailability/routes'

import ModalSelectors from 'modules/domain/modal/selectors'
import ModalActions from 'modules/domain/modal/duck'
import FccOrderSkuPage from './pages/FccOrderSku/FccOrderSkuPage'
import FccOrderSkuRoutes from './pages/FccOrderSku/routes'
import ProductSettingsRoutes from './pages/ProductSettings/routes'
import ProductSettingsPage from './pages/ProductSettings/ProductSettingsPage'
import TargetSkuPage from './pages/Target2Sku/TargetSkuPage'
import Target2SkuRoutes from './pages/Target2Sku/routes'
import PageWrapper from './components/PageWrapper/PageWrapper'
import { SnackbarSelectors } from 'modules/domain/snackbar/selectors'
import SnackbarActions from 'modules/domain/snackbar/duck'
import ChangeLogRoutes from './pages/ChangeLog/routes'
import ChangeLogPage from './pages/ChangeLog/ChangeLogPage'
import ReturnDeclarationSkuRoutes from './pages/ReturnDeclarationSku/routes'
import ReturnDeclarationSkuPage from './pages/ReturnDeclarationSku/ReturnDeclarationSkuPage'
import { useIntercom } from 'hooks/useIntercom'

const SpinnerWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Route: React.FC<RouteProps & { capability: CAPABILITY }> = ({ capability, children, ...props }) => {
  const isRoutePermitted = usePermissions({ capability, permission: PERMISSION.R })
  return (
    <ReactRouterRoute {...props}>
      {isRoutePermitted ? children : <Redirect to={DashboardRoutes.Dashboard} />}
    </ReactRouterRoute>
  )
}

const Wrapper: React.FC = ({ children }) => {
  const { helmet } = useContext(HelmetContext)

  return (
    <>
      <Helmet>
        <title>{helmet.title}</title>
        <meta name="description" content={helmet.description} />
        <link rel="icon" href={helmet?.favicons?.ico} />
        <link rel="icon" href={helmet?.favicons?.svg} type="image/svg+xml" />
      </Helmet>
      {children}
    </>
  )
}

const Intercom: React.VFC<{ id?: string }> = ({ id }) => {
  useIntercom(id)
  return null
}

const ZendeskWidget: React.VFC<{ id?: string }> = ({ id }) => {
  const language = useLanguage()
  const profile = useSelector(AuthSelectors.profile)
  useZendeskWidget(id, {
    language,
    email: profile?.email ?? '',
    name: profile?.first_name + ' ' + profile?.last_name,
    phone: profile?.phone_number ?? '',
  })
  return null
}

const RootComponent: React.FC = () => {
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => checkFeatureFlag(flag), [checkFeatureFlag])
  const checkWLFeatureFlag = useWLFeatureFlags()

  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const { content: ModalContent, contentProps: modalContentProps, ...modalProps } = useSelector(ModalSelectors.props)
  const { content: SnackbarContent, contentProps: snackbarContentProps, ...snackbarProps } = useSelector(
    SnackbarSelectors.props,
  )

  const closeModal = useAction(ModalActions.close)
  const closeSnackbar = useAction(SnackbarActions.close)

  const initAction = useAction(AuthActions.init)
  const role = useSelector(AuthSelectors.role)
  const initProgress = useSelector(AuthSelectors.getInitProgress)
  const [configProgress, config] = useConfig()
  useHelmet({ favicons: config?.favicons })

  useEffect(() => {
    initAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if ([Progress.WORK, Progress.IDLE].includes(initProgress) || configProgress === Progress.WORK) {
    return (
      <Wrapper>
        <SpinnerWrap>
          <SpinnerLayout />
        </SpinnerWrap>
      </Wrapper>
    )
  }

  const intercomKey = config?.third_party_keys?.intercom_app_id || env.INTERCOM_APP_ID
  const zendeskKey = config?.third_party_keys?.zendesk_key || env.ZENDESK_KEY

  return (
    <Wrapper>
      {(configProgress === Progress.SUCCESS || configProgress === Progress.ERROR) &&
        (zendeskKey ? <ZendeskWidget id={zendeskKey} /> : <Intercom id={intercomKey} />)}
      <Switch>
        <ReactRouterRoute path={'/auth'}>
          <AuthPage />
        </ReactRouterRoute>
        <ReactRouterRoute path="*">
          <PageWrapper>
            <Switch>
              <Route path={UserRoutes.List} capability={CAPABILITY.USERS}>
                <UserPage />
              </Route>
              <Route path={FarmerRoutes.List} capability={CAPABILITY.FARMERS}>
                <FarmerPage />
              </Route>
              <Route path={CompanyRoutes.List} capability={CAPABILITY.COMPANIES}>
                <CompanyPage />
              </Route>
              <Route path={BadgeRoutes.List} capability={CAPABILITY.PRODUCTS}>
                <BadgePage />
              </Route>
              <Route path={ContactRoutes.List} capability={CAPABILITY.CONTACTS}>
                <ContactPage />
              </Route>
              <Route path={NoteRoutes.List} capability={CAPABILITY.CONTACTS}>
                <NotePage />
              </Route>
              {(isAgro(role) ||
                (checkFeatureFlag(
                  generateCrmSectionAccessString(Sections.FarmerOrders, FeatureFlagModifiers.Enabled),
                ) &&
                  checkWLFeatureFlag(
                    generateCrmSectionAccessString(Sections.Storefront, FeatureFlagModifiers.Enabled),
                  ))) && (
                <Route path={FarmerOrderSkuRoutes.List} capability={CAPABILITY.SKU_ORDERS}>
                  <FarmerOrderSkuPage />
                </Route>
              )}
              {(isAgro(role) ||
                (hasAccess(generateCrmSectionAccessString(Sections.FCCReport, FeatureFlagModifiers.Enabled)) &&
                  checkWLFeatureFlag(
                    generateCrmSectionAccessString(Sections.Storefront, FeatureFlagModifiers.Enabled),
                  ))) && (
                <Route path={FccOrderSkuRoutes.List} capability={CAPABILITY.ORDERS}>
                  <FccOrderSkuPage />
                </Route>
              )}
              {(isAgro(role) ||
                (hasAccess(generateCrmSectionAccessString(Sections.RetailerOrders, FeatureFlagModifiers.Enabled)) &&
                  checkWLFeatureFlag(
                    generateCrmSectionAccessString(Sections.Storefront, FeatureFlagModifiers.Enabled),
                  ))) && (
                <Route path={DistributorOrderSkuRoutes.List} capability={CAPABILITY.DISTRIBUTOR_ORDERS_SKU}>
                  <DistributorOrderSkuPage />
                </Route>
              )}
              <Route path={CategoryRoutes.CategoryList} capability={CAPABILITY.CATEGORIES}>
                <CategoryPage />
              </Route>
              <Route path={UpTakeRoutes.List} capability={CAPABILITY.UPTAKE}>
                <UpTakePage />
              </Route>
              <Route path={HouseholdRoutes.List} capability={CAPABILITY.HOUSEHOLDS}>
                <HouseholdPage />
              </Route>
              {(isAgro(role) ||
                hasAccess(generateCrmSectionAccessString(Sections.Reconciliations, FeatureFlagModifiers.Enabled))) && (
                <Route path={ReconciliationRoutes.Groups} capability={CAPABILITY.RECONCILIATION}>
                  <ReconciliationPage />
                </Route>
              )}
              <Route path={SeasonRoutes.List} capability={CAPABILITY.SEASONS}>
                <SeasonPage />
              </Route>
              <Route path={LicenseRoutes.List} capability={CAPABILITY.LICENSES}>
                <LicensePage />
              </Route>
              {(isAgro(role) ||
                hasAccess(generateCrmSectionAccessString(Sections.Targets2, FeatureFlagModifiers.Enabled))) && (
                <Route path={Target2SkuRoutes.DefaultList} capability={CAPABILITY.FARMER_TARGETS}>
                  <TargetSkuPage />
                </Route>
              )}
              {(isAgro(role) ||
                hasAccess(generateCrmSectionAccessString(Sections.ChangeLog, FeatureFlagModifiers.Enabled))) && (
                <Route path={ChangeLogRoutes.List} capability={CAPABILITY.CHANGE_LOG}>
                  <ChangeLogPage />
                </Route>
              )}
              {(checkWLFeatureFlag(generateCrmSectionAccessString(Sections.Storefront, FeatureFlagModifiers.Enabled)) ||
                isAdmin) && (
                <ReactRouterRoute path={StorefrontRoutes.List} capability={CAPABILITY.STOREFRONT}>
                  <StorefrontPage />
                </ReactRouterRoute>
              )}
              {isAdmin && (
                <Route path={ProgramSummaryRoutes.List} capability={CAPABILITY.PROGRAM_SUMMARY}>
                  <ProgramSummaryPage />
                </Route>
              )}
              <ReactRouterRoute path={DashboardRoutes.Dashboard}>
                <Dashboard />
              </ReactRouterRoute>
              <ReactRouterRoute path={TerritoryRoutes.List}>
                <TerritoryPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={ReportRoutes.ReportList}>
                <ReportPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={PromocodeRoutes.List}>
                <PromocodePage />
              </ReactRouterRoute>
              <ReactRouterRoute path={PromoInfoRoutes.PromoInfoList}>
                <PromoInfoPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={DocumentRoutes.List}>
                <DocumentPage />
              </ReactRouterRoute>
              {(isAgro(role) ||
                hasAccess(
                  generateCrmSectionAccessString(Sections.ReturnDeclarations, FeatureFlagModifiers.Enabled),
                )) && (
                <ReactRouterRoute path={ReturnDeclarationSkuRoutes.List}>
                  <ReturnDeclarationSkuPage />
                </ReactRouterRoute>
              )}
              <ReactRouterRoute path={DiscountRuleRoutes.List}>
                <DiscountRulePage />
              </ReactRouterRoute>
              <ReactRouterRoute path={IncentiveCampaignRoutes.List}>
                <IncentiveCampaignPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={IncentiveProgramRoutes.List}>
                <IncentiveProgramPage />
              </ReactRouterRoute>
              {(isAgro(role) ||
                hasAccess(
                  generateCrmSectionAccessString(Sections.InventoryExchange, FeatureFlagModifiers.Enabled),
                )) && (
                <ReactRouterRoute
                  path={InventoryExchangeRoutes.TransactionsList}
                  capability={CAPABILITY.INVENTORY_EXCHANGE}
                >
                  <InventoryExcnahgePage />
                </ReactRouterRoute>
              )}
              <ReactRouterRoute path={FarmerPlansRoutes.List}>
                <FarmerPlansPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={SalesTeamPlansRoutes.List}>
                <SalesTeamPlansPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={MarketingCampaignsRoutes.List}>
                <MarketingCampaignsPage />
              </ReactRouterRoute>
              <ReactRouterRoute path={NotificationsRoutes.List}>
                <NotificationsPage />
              </ReactRouterRoute>
              {(isAgro(role) ||
                hasAccess(generateCrmSectionAccessString(Sections.ProductSettings, FeatureFlagModifiers.Enabled))) && (
                <Route path={ProductSettingsRoutes.List} capability={CAPABILITY.PRODUCT_SETTINGS}>
                  <ProductSettingsPage />
                </Route>
              )}
              {(isAgro(role) ||
                hasAccess(generateCrmSectionAccessString(Sections.Allocation, FeatureFlagModifiers.Enabled))) && (
                <ReactRouterRoute path={AllocationRoutes.Main} capability={CAPABILITY.ALLOCATION}>
                  <AllocationPage />
                </ReactRouterRoute>
              )}
              {(isAgro(role) ||
                hasAccess(
                  generateCrmSectionAccessString(Sections.ProductsAvailability, FeatureFlagModifiers.Enabled),
                )) && (
                <ReactRouterRoute path={ProductsAvailabilityRoutes.List} capability={CAPABILITY.PRODUCTS_AVAILABILITY}>
                  <ProductsAvailabilityPage />
                </ReactRouterRoute>
              )}
              <Redirect to={DashboardRoutes.Dashboard} />
            </Switch>
          </PageWrapper>
        </ReactRouterRoute>
      </Switch>
      <Modal
        {...modalProps}
        onClose={() => {
          modalProps.onClose?.()
          closeModal()
        }}
      >
        {ModalContent &&
          (React.isValidElement(ModalContent) || typeof ModalContent === 'string' ? (
            ModalContent
          ) : (
            <ModalContent {...modalContentProps} />
          ))}
      </Modal>
      <Snackbar
        {...snackbarProps}
        onClose={() => {
          snackbarProps.onClose?.()
          closeSnackbar()
        }}
        onHide={() => {
          snackbarProps.onHide?.()
          closeSnackbar()
        }}
      >
        {SnackbarContent &&
          (React.isValidElement(SnackbarContent) || typeof SnackbarContent === 'string' ? (
            SnackbarContent
          ) : (
            <SnackbarContent {...snackbarContentProps} />
          ))}
      </Snackbar>
    </Wrapper>
  )
}

export default RootComponent
