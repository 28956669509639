import styled from 'styled-components'
import { Button, IconBin, Checkbox as CheckboxComponent } from '@agro-club/frontend-shared'

export const NotePersonalDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'NoteTextArea';
`

export const NoteTextArea = styled.div`
  display: grid;
  grid-gap: 16px;
  min-width: 550px;
`

export const PermissionsWrapper = styled.div`
  width: 200px;
`

export const RemoveButton = styled(Button)`
  margin-left: auto;
`

export const RemoveBtn = styled(IconBin)`
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 12px;
  transition: 0.3s fill;

  &:hover {
    fill: ${({ theme }) => theme.color.primary500};
  }
`

export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`

export const EmptyList = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const CommentBlock = styled.div`
  width: 480px;
`

export const EmailSwitchArea = styled.div`
  margin-top: 8px;
`
