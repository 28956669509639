import React, { useCallback } from 'react'
import ContactDetailsForm, { FormData } from './ContactDetailsForm/ContactDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import ContactActions from 'modules/domain/contact/duck'
import ContactSelectors from 'modules/domain/contact/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import CompanyRoutes from 'views/pages/Company/routes'
import { useAction, useHelmet } from '@agro-club/frontend-shared'
import { ContactType, Status } from 'types/entities'
import { zip } from 'ramda'
import { useCompanyById } from 'modules/domain/company/hooks'

const ContactId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const ContactAdd: React.FC = () => {
  const params = useParams<{ company_id: string }>()
  const history = useHistory()
  const { t } = useTranslation(['contact', 'common'])
  const contactAddAction = useAction(ContactActions.addRequested)

  const handleSubmit = useCallback(
    (fields: Required<FormData>) => {
      contactAddAction({
        company_id: params.company_id,
        contact_type: fields.contact_type,
        first_name: fields.first_name,
        last_name: fields.last_name,
        email: fields.email,
        phone: fields.phone,
        additional_phone: fields.additional_phone,
        address: fields.address,
        department: fields.department,
        social_media: fields.social_media,
        birthday: fields.birthday,
        com_prefs_ids: fields.com_prefs_ids,
        contact_roles_ids: fields.contact_roles_ids,
        status: fields.status,
        slug: fields.slug,
      })
    },
    [contactAddAction],
  )

  const goBack = () => history.push(generatePath(CompanyRoutes.Edit, { id: params.company_id }))
  const progress = useSelector(ContactSelectors.addProgress)

  useHelmet({ title: t('addContactMetaTitle') })

  let companyIdString = ''
  const [companyFetchProgress, company] = useCompanyById(params.company_id)
  if (companyFetchProgress !== Progress.ERROR) {
    companyIdString = zip(['COMPANY'], [company?.official_name || ''])
      .filter(([, id]) => !!id)
      .map(arr => arr.join(': '))
      .join(', ')
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('form.add')} />
          <ContactId>{companyIdString}</ContactId>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ContactDetailsForm
          contact_id={undefined}
          onCancel={goBack}
          mode={'create'}
          initialValues={{
            company_id: params.company_id,
            contact_type: ContactType.Person,
            status: Status.Active,
          }}
          onSubmit={handleSubmit}
          progress={progress}
        />
      </Layout.Content>
    </>
  )
}

export default ContactAdd
