import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Note } from 'modules/domain/note/types'
import { EntityMetadata } from 'modules/domain/types'

const notesDict = (state: AppGlobalState) => state.note.items
const ids = (state: AppGlobalState) => state.note.ids
const note = (state: AppGlobalState, id: string): Note | undefined => state.note.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Note> =>
  state.note.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const itemFetchProgress = (state: AppGlobalState) => state.note.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.note.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.note.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.note.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.note.listFetchError
const noteList = createSelector(notesDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Note[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.note.filter
const sorting = (state: AppGlobalState) => state.note.sorting
const page = (state: AppGlobalState) => state.note.page
const total = (state: AppGlobalState) => state.note.total
const updateProgress = (state: AppGlobalState) => state.note.updateProgress
const updateError = (state: AppGlobalState) => state.note.updateError
const updateErrorDetail = (state: AppGlobalState) => state.note.updateErrorDetail
const removeProgress = (state: AppGlobalState) => state.note.removeProgress
const addProgress = (state: AppGlobalState) => state.note.addProgress
const addError = (state: AppGlobalState) => state.note.addError
const addErrorDetail = (state: AppGlobalState) => state.note.addErrorDetail
const hasNext = (state: AppGlobalState) => state.note.total > state.note.ids.length
const pageSize = (state: AppGlobalState) => state.note.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.note.total / state.note.pageSize)

const NoteSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  addErrorDetail,
  updateError,
  updateErrorDetail,
  notesDict,
  ids,
  note,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  noteList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default NoteSelectors
