import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Routes from './routes'

import NoteEdit from './NoteEdit'
import NoteAdd from './NoteAdd'

const NotePage = () => {
  return (
    <Switch>
      <Route path={Routes.Edit} exact={true}>
        <NoteEdit />
      </Route>
      <Route path={Routes.Add} exact={true}>
        <NoteAdd />
      </Route>
    </Switch>
  )
}

export default NotePage
