import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/Contact/ContactDetailsForm/types'
import * as Styled from 'views/pages/Contact/ContactDetailsForm/styled'
import { DatePicker, Input, FormComponents } from '@agro-club/frontend-shared'
import { CountryCode } from 'libphonenumber-js'
import { useSelector } from 'react-redux'
import ContactSelectors from 'modules/domain/contact/selectors'
import { Progress } from 'modules/types'
import { DocumentItem } from 'modules/domain/document/types'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import AddressForm from 'views/components/AddressForm/AddressForm'
import useDateFormat from 'hooks/useDateFormat'
import CommunicationPreferenceSelect from 'views/components/CommunicationPreferenceSelect/CommunicationPreferenceSelect'
import ContactRoleSelect from 'views/components/ContactRoleSelect/ContactRoleSelect'

type PropsType = {
  inOrderCreationMode?: boolean
  mode: 'edit' | 'create'
  userId?: string
  documents?: DocumentItem[]
  countryCodeFromPhone?: CountryCode
  isEmailOptional?: boolean
  handleChangeEmailOptional?: (value: boolean) => void
  onCountryCodeChange?: (value: CountryCode) => void
}

const ContactForm: React.FC<PropsType> = ({ inOrderCreationMode = false, onCountryCodeChange }: PropsType) => {
  const { t } = useTranslation(['contact', 'common', 'validation', 'contactOrder'])
  const formik = useFormikContext<FormFields>()

  const addErrorDetail = useSelector(ContactSelectors.addErrorDetail)
  const updateErrorDetail = useSelector(ContactSelectors.updateErrorDetail)
  const addProgress = useSelector(ContactSelectors.addProgress)
  const updateProgress = useSelector(ContactSelectors.updateProgress)

  useEffect(() => {
    if ([addProgress, updateProgress].includes(Progress.ERROR)) {
      if ([addErrorDetail, updateErrorDetail].includes('email_exists')) {
        formik.setFieldError('email', t('errors.emailTaken'))
      } else if ([addErrorDetail, updateErrorDetail].includes('phone_exists')) {
        formik.setFieldError('phone', t('errors.phoneTaken'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addErrorDetail, updateErrorDetail, addProgress, updateProgress])

  const dateFormat = useDateFormat({ isYearShort: true })

  return (
    <>
      <FormComponents.FormSection title={t('form.personaData')}>
        <Styled.ContactPersonalDataWrapper>
          <Styled.NameArea>
            <Input
              label={t('common:firstName')}
              {...formik.getFieldProps('first_name')}
              invalid={formik.touched.first_name && !!formik.errors.first_name}
              errorText={formik.errors.first_name}
              required
            />
          </Styled.NameArea>
          <Styled.LastNameArea>
            <Input
              label={t('common:lastName')}
              {...formik.getFieldProps('last_name')}
              invalid={formik.touched.last_name && !!formik.errors.last_name}
              errorText={formik.errors.last_name}
              required
            />
          </Styled.LastNameArea>
          <Styled.PhoneNumberArea>
            <PhoneInput
              name={'phone'}
              label={t('common:phone')}
              invalid={formik.touched.phone && !!formik.errors.phone}
              errorText={formik.errors.phone}
              phoneNumber={formik.values.phone}
              onChange={(phone, _, __, ___, countryCode: CountryCode) => {
                onCountryCodeChange?.(countryCode)
                formik.setFieldValue('phone', phone)
              }}
              onBlur={() => {
                formik.setFieldTouched('phone', true)
              }}
              disabled={inOrderCreationMode}
              testId={'contact-phone-number'}
              required
            />
          </Styled.PhoneNumberArea>
          <Styled.AdditionalPhoneNumberArea>
            <PhoneInput
              name={'additional_phone'}
              label={t('common:additionalPhone')}
              invalid={formik.touched.additional_phone && !!formik.errors.additional_phone}
              errorText={formik.errors.additional_phone}
              phoneNumber={formik.values.additional_phone || ''}
              onChange={additional_phone => {
                formik.setFieldValue('additional_phone', additional_phone)
              }}
              onBlur={() => {
                formik.setFieldTouched('additional_phone', true)
              }}
            />
          </Styled.AdditionalPhoneNumberArea>
          <Styled.EmailArea>
            <Input
              label={t('common:email')}
              {...formik.getFieldProps('email')}
              invalid={formik.touched.email && !!formik.errors.email}
              errorText={formik.errors.email}
            />
          </Styled.EmailArea>
          <Styled.DepartmentArea>
            <Input
              label={t('contact:form.title')}
              {...formik.getFieldProps('department')}
              invalid={formik.touched.department && !!formik.errors.department}
              errorText={formik.errors.department}
            />
          </Styled.DepartmentArea>
          <Styled.SocialMediaArea>
            <Input
              label={t('contact:form.socialMedia')}
              {...formik.getFieldProps('social_media')}
              invalid={formik.touched.social_media && !!formik.errors.social_media}
              errorText={formik.errors.social_media}
            />
          </Styled.SocialMediaArea>
          <Styled.BirthdayArea>
            <DatePicker
              {...formik.getFieldProps('birthday')}
              date={formik.values.birthday}
              onChange={(_, date) => {
                if (date) {
                  const fix = (v: number) => (v < 10 ? `0${v}` : v)
                  const y = date.getFullYear()
                  const m = date.getMonth() + 1
                  const d = date.getDate()
                  const hh = date.getHours()
                  const mm = date.getMinutes()
                  const ss = date.getSeconds()
                  const str = `${y}-${fix(m)}-${fix(d)}T${fix(hh)}:${fix(mm)}:${fix(ss)}.000Z`
                  formik.setFieldValue('birthday', str)
                } else {
                  formik.setFieldValue('birthday', '')
                }
              }}
              label={t('contact:form.birthday')}
              format={dateFormat}
            />
          </Styled.BirthdayArea>
          <Styled.ComPrefArea>
            <CommunicationPreferenceSelect
              isClearable
              label={t('common:compref')}
              values={formik.values.com_prefs_ids}
              onChange={value => formik.setFieldValue('com_prefs_ids', value)}
              placeholder={t('status.any')}
            />
          </Styled.ComPrefArea>
          <Styled.ContactRolesArea>
            <ContactRoleSelect
              isClearable
              label={t('contact:form.contactRoles')}
              values={formik.values.contact_roles_ids}
              onChange={value => formik.setFieldValue('contact_roles_ids', value)}
            />
          </Styled.ContactRolesArea>
        </Styled.ContactPersonalDataWrapper>
      </FormComponents.FormSection>
      <FormComponents.FormSection title={t('contact:address')}>
        <AddressForm field={'address'} />
      </FormComponents.FormSection>
    </>
  )
}

export default ContactForm
