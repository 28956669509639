import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as managers from 'modules/domain/contact/managers'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { CommunicationPreference, CommunicationPreferenceListRequestFilter } from 'modules/domain/contact/types'

export type CommunicationPreferenceSelectProps = EntityMultiSelectProps<CommunicationPreference> & {
  details?: CommunicationPreferenceListRequestFilter['details']
}

export const getCommunicationPreferenceLabel = (communicationPreference: CommunicationPreference) =>
  communicationPreference.details
export const getCommunicationPreferenceValue = (communicationPreference: CommunicationPreference) =>
  communicationPreference.id

const CommunicationPreferenceSelect: React.FC<CommunicationPreferenceSelectProps> = ({
  details,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation('common')
  const memoizedFilter = useMemo(
    () => ({
      details,
    }),
    [details],
  )

  return (
    <SelectTestWrapper data-test-id={'contact-role-select'}>
      <EntityMultiSelect
        placeholder={placeholder || t('placeholders.communicationPreferences')}
        getEntityList={managers.fetchCommunicationPreferences}
        getEntityByIds={managers.getCommunicationPreferencesByIds}
        filter={memoizedFilter}
        getOptionLabel={getCommunicationPreferenceLabel}
        getOptionValue={getCommunicationPreferenceValue}
        searchParamKey="title"
        {...props}
      />
    </SelectTestWrapper>
  )
}

export default CommunicationPreferenceSelect
