import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routes from './routes'

import ContactTable from 'views/pages/Contact/ContactTable'
import ContactEdit from './ContactEdit'
import ContactAdd from './ContactAdd'

const ContactPage = () => {
  return (
    <Switch>
      <Route path={Routes.List} exact={true}>
        <ContactTable />
      </Route>
      <Route path={Routes.Edit} exact={true}>
        <ContactEdit />
      </Route>
      <Route path={Routes.Add} exact={true}>
        <ContactAdd />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  )
}

export default ContactPage
