import { useSelector } from 'react-redux'
import ContactSelectors from 'modules/domain/contact/selectors'
import { Progress, ResourceHook } from 'modules/types'
import ContactActions from 'modules/domain/contact/duck'
import { Contact, ContactListRequestFilter } from 'modules/domain/contact/types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useContactList: ResourceHook<Contact[], void[]> = () => {
  const progress = useSelector(ContactSelectors.listFetchProgress)
  const list = useSelector(ContactSelectors.contactList)
  const page = usePageQuery()
  const fetchAction = useAction(ContactActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useContactListForCompany: ResourceHook<Contact[], [string]> = (company_id: string) => {
  const progress = useSelector(ContactSelectors.listFetchProgress)
  const list = useSelector(ContactSelectors.contactList)
  const page = usePageQuery()
  const params: { filter?: ContactListRequestFilter; page?: number } = {}

  if (company_id) params.filter = { company_id: company_id }
  if (page) params.page = page
  const fetchAction = useAction(ContactActions.listRequested, params)

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useContact: ResourceHook<Contact, [string]> = (id: string) => {
  const meta = useSelector(state => ContactSelectors.meta(state, id))
  const contact = useSelector(state => ContactSelectors.contact(state, id))
  const fetchAction = useAction(ContactActions.itemRequested, id)

  useDidMount(() => {
    if (!contact || contact.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, contact]
}
