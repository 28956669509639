import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import ContactList from './ContactList/ContactList'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ContactActions from 'modules/domain/contact/duck'
import ContactSelectors from 'modules/domain/contact/selectors'
import { useSelector } from 'react-redux'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const ContactTable = () => {
  const filter = useSelector(ContactSelectors.filter)
  const filterUpdated = useAction(ContactActions.filterUpdated)
  const { t } = useTranslation('contact')

  const handleSearchChange = useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  useHelmet({ title: t('contactsMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}></Header.Title>
          <SearchInputWrapper>
            <SearchInput
              onChange={handleSearchChange}
              value={filter.search}
              placeholder={t('list.searchPlaceholder')}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ContactList />
      </Layout.Content>
    </>
  )
}

export default ContactTable
