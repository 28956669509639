import React, { useCallback } from 'react'
import FarmerDetailsForm, { FormData } from './FarmerDetailsForm/FarmerDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import FarmerActions from 'modules/domain/farmer/duck'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FarmerRoutes from 'views/pages/Farmer/routes'
import { ROLES } from 'types/entities'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const FarmerAdd: React.FC = () => {
  const { t } = useTranslation('farmer')
  const push = useHistoryPush()
  const farmerAddAction = useAction(FarmerActions.addRequested)
  const page = useSelector(FarmerSelectors.page)

  const handleSubmit = useCallback(
    (fields: Required<FormData>) => {
      farmerAddAction({
        email: fields.email,
        first_name: fields.firstName,
        second_name: fields.middleName,
        last_name: fields.lastName,
        canterra_id: fields.canterraId,
        status: fields.status,
        farm_status: fields.farm_status,
        phone_number: fields.phone,
        country: fields.countryCode, // TODO fix
        regions: [],
        farm_name: fields.farmName,
        self_pickup: fields.selfPickup,
        partnership: fields.partnership,
        legal_address: fields.legal_address,
        delivery_address: fields.delivery_address,
        does_not_have_email: fields.does_not_have_email,
        role: ROLES.USER,
        csua_info: fields.csua_info,
        retailer_regions_ids: fields.retailer_regions_ids,
        comment: fields.comment,
        additional_phone: fields.additionalPhone,
        timezone: fields.timezone,
        contact_info: fields.contact_info,
      })
    },
    [farmerAddAction],
  )

  const goBack = () => push({ route: FarmerRoutes.List, query: { page } })
  const progress = useSelector(FarmerSelectors.addProgress)

  useHelmet({ title: t('addFarmerMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <FarmerDetailsForm onCancel={goBack} mode={'create'} onSubmit={handleSubmit} progress={progress} />
      </Layout.Content>
    </>
  )
}

export default FarmerAdd
