import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { FormikContext, useFormik } from 'formik'
import { Button, SectionBody, SectionContainer } from '@agro-club/frontend-shared'
import { Status, ContactType } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import { FormFields } from 'views/pages/Contact/ContactDetailsForm/types'
import ContactForm from 'views/pages/Contact/ContactDetailsForm/ContactForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { DocumentItem } from 'modules/domain/document/types'
import { Household } from 'modules/domain/household/types'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useDetectedCountry } from '../../../../hooks/useDetectedCountry'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import styled, { StyledProps } from 'styled-components'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import ContactSelectors from 'modules/domain/contact/selectors'
import { noop } from 'helpers/noop'
import ContactNotes from './ContactNotes'

export type FormData = FormFields

export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  margin-bottom: 16px;
`

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
`

type Props = {
  contact_id?
  mode: 'edit' | 'create'
  onSubmit(values: FormData): void
  progress?: Progress
  onCancel(): void
  onRemove?(): void
  userId?: string
  // we can't edit user documents
  // we can only upload new file for existing user document
  // this is why they are separated from the initialValues
  documents?: DocumentItem[]
  initialValues?: FormData
  household?: Household
}

const ContactDetailsForm: React.FC<Props> = ({
  contact_id,
  mode,
  onSubmit,
  progress,
  onCancel,
  onRemove,
  userId,
  documents = [],
  initialValues: {
    company_id = '',
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
    additional_phone = '',
    address,
    department = '',
    social_media = '',
    birthday,
    com_prefs_ids,
    contact_roles_ids,
    status = Status.Active,
    slug,
  } = {},
}) => {
  const { t } = useTranslation(['contact', 'common', 'validation'])
  const getDefaultCountry = useDetectedCountry()
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  const removeProgress = useSelector(ContactSelectors.removeProgress)

  const schema = useMemo(() => {
    const addressSchema = Yup.object().shape({
      address: Yup.string().nullable(),
      city: Yup.string().nullable(),
      postal_code: Yup.string().nullable(),
      region_id: Yup.string().nullable(),
    })

    return Yup.object().shape({
      first_name: Yup.string().required(t('validation:field_required')),
      last_name: Yup.string().required(t('validation:field_required')),
      email: Yup.string().email(t('validation:email_invalid')),
      phone: Yup.string()
        .required(t('validation:field_required'))
        .test('is-valid-number', t('validation:invalid_phone_number'), value => isValidPhoneNumber(value || '')),
      additional_phone: Yup.string().nullable(),
      address: addressSchema,
      department: Yup.string().nullable(),
      social_media: Yup.string().nullable(),
      birthday: Yup.date().nullable(),
      com_prefs_ids: Yup.array(Yup.string()),
      contact_roles_ids: Yup.array(Yup.string()),
      status: Yup.mixed().oneOf([Status.Active, Status.Inactive, Status.Deleted]),
      notes: Yup.string(),
    })
  }, [t, phoneCodes])

  const handleSubmit = () => {
    if (!formik.isValid) {
      return
    }
    const { ...common } = formik.values

    const result: FormData = {
      ...common,
    }

    result.address = formik.values.address
      ? {
          ...formik.values.address,
          country: formik.values.address.country || getDefaultCountry,
        }
      : null

    onSubmit(result)
  }

  const formik = useFormik<FormFields>({
    initialValues: {
      company_id,
      contact_type: ContactType.Person,
      first_name,
      last_name,
      email,
      phone,
      additional_phone,
      address: address,
      department,
      social_media,
      birthday,
      com_prefs_ids,
      contact_roles_ids,
      status,
      slug,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <FormikContext.Provider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Wrapper>
            <Column>
              <SectionContainer>
                <SectionBody>
                  <ContactForm documents={documents} mode={mode} userId={userId} />
                </SectionBody>
              </SectionContainer>
            </Column>
            {contact_id !== undefined && (
              <Column>
                <SectionContainer>
                  <SectionBody>
                    <ContactNotes contact_id={contact_id} />
                  </SectionBody>
                </SectionContainer>
              </Column>
            )}
          </Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            name={'submit-button'}
            filled={true}
            progress={progress}
            disabled={!formik.dirty || progress === Progress.WORK}
            intent={'primary'}
            onClick={formik.submitForm}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
          {isAdmin && mode === 'edit' && (
            <StickyFooterDeleteBtn
              onRemove={onRemove || noop}
              removeProgress={removeProgress}
              popoverText={t('form.removeContact', { name: `${first_name} ${last_name}` })}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikContext.Provider>
  )
}

export default ContactDetailsForm
