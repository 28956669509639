import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as managers from 'modules/domain/contact/managers'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { ContactRole, ContactRoleListRequestFilter } from 'modules/domain/contact/types'

export type ContactRoleSelectProps = EntityMultiSelectProps<ContactRole> & {
  details?: ContactRoleListRequestFilter['details']
}

export const getContactRoleLabel = (contactRole: ContactRole) => contactRole.details
export const getContactRoleValue = (contactRole: ContactRole) => contactRole.id

const ContactRoleSelect: React.FC<ContactRoleSelectProps> = ({ details, placeholder, ...props }) => {
  const { t } = useTranslation('common')
  const memoizedFilter = useMemo(
    () => ({
      details,
    }),
    [details],
  )

  return (
    <SelectTestWrapper data-test-id={'contact-role-select'}>
      <EntityMultiSelect
        placeholder={placeholder || t('placeholders.contactRoles')}
        getEntityList={managers.fetchContactRoles}
        getEntityByIds={managers.getContactRolesByIds}
        filter={memoizedFilter}
        getOptionLabel={getContactRoleLabel}
        getOptionValue={getContactRoleValue}
        searchParamKey="title"
        {...props}
      />
    </SelectTestWrapper>
  )
}

export default ContactRoleSelect
