import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import styled from 'styled-components'
import { Note, NoteListRequestFilter } from 'modules/domain/note/types'
import NoteSelectors from 'modules/domain/note/selectors'
import NoteActions from 'modules/domain/note/duck'
import { useNoteList } from 'modules/domain/note/hooks'
import { NoteType } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import useDateFormatFn from 'hooks/useDateFormatFn'
import Routes from '../routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  useDidMount,
} from '@agro-club/frontend-shared'

const TableStyled = styled(Table)`
  min-width: 354px;
`

type NoteListProps = {
  parent_id_filter: string
  note_type: NoteType
}

const NotesSortableHeadCell = AdvancedHeadCell<keyof Note>()

const CreatedDateCell: React.FC<CellProps<Note, string>> = ({ cell, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return <div key={column.id}>{getFormatedDate(cell.value) || ''}</div>
}

const NoteList: React.FC<NoteListProps> = ({ parent_id_filter, note_type }) => {
  const history = useHistory()
  const { t } = useTranslation(['notes', 'labels'])
  const [progress, data] = useNoteList()
  const filterUpdated = useAction(NoteActions.filterUpdated)
  const sortingUpdated = useAction(NoteActions.sortingUpdated)
  const listRequested = useAction(NoteActions.listRequested)
  const filterValue = useSelector(NoteSelectors.filter)
  const total = useSelector(NoteSelectors.total)
  const pages = useSelector(NoteSelectors.pages)
  const page = useSelector(NoteSelectors.page)
  const pageSize = useSelector(NoteSelectors.pageSize)
  let { search, parent_id } = filterValue
  const { sort_field, sort_reversed } = useSelector(NoteSelectors.sorting)

  useDidMount(() => {
    handleClearFilters()
    search = undefined
    parent_id = parent_id_filter
    note_type = note_type
    handleFilterChange({ search, parent_id, note_type })
  })

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.date'),
        accessor: 'created_at' as const,
        sortable: true,
        Cell: CreatedDateCell,
      },
      {
        Header: t('list.tableHeaders.user'),
        accessor: 'created_by' as const,
      },
      {
        Header: t('list.tableHeaders.subject'),
        accessor: 'note_title' as const,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof Note)[] = useMemo(() => ['note_type'], [])

  const columnsAll = useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const { columns, rows, prepareRow } = useTable<Note>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Note[],
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<NoteListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('noteList')

  return (
    <TComponents.Wrapper>
      <TableStyled
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={setPage}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <NotesSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof Note}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </NotesSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{t('list.emptyMsg')}</div>
          </TableNoData>
        </TableBody>
      </TableStyled>
    </TComponents.Wrapper>
  )
}

export default NoteList
