import React, { useMemo, useState } from 'react'
import * as Yup from 'yup'
import { FormikContext, useFormik } from 'formik'
import { Button, SectionBody, SectionContainer, useAction } from '@agro-club/frontend-shared'
import { Status } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import { FormFields } from 'views/pages/Farmer/FarmerDetailsForm/types'
import FarmerForm from 'views/pages/Farmer/FarmerDetailsForm/FarmerForm'
import { PaperAgreementStatus } from 'modules/domain/farmer/types'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { DocumentItem } from 'modules/domain/document/types'
import { Household } from 'modules/domain/household/types'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { DEFAULT_COUNTRY } from 'modules/constants'
import { useDetectedCountry } from '../../../../hooks/useDetectedCountry'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { useSelector } from 'react-redux'
import DocumentActions from 'modules/domain/document/duck'
import FarmerMetaCard from 'views/components/FarmerMetaCard/FarmerMetaCard'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useFarmerMetaData } from 'modules/domain/farmer/hooks'
import styled, { StyledProps } from 'styled-components'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { noop } from 'helpers/noop'
import FarmerOrdersInfo from './FarmerOrdersInfo'
import { isValidSpainPhoneNumber } from 'views/components/CustomerModal/helpers'
import FarmerNotes from './FarmerNotes'

export type FormData = FormFields

export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  margin-bottom: 16px;
`

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
`

type Props = {
  mode: 'edit' | 'create'
  onSubmit(values: FormData): void
  progress?: Progress
  onCancel(): void
  onRemove?(): void
  userId?: string
  // we can't edit user documents
  // we can only upload new file for existing user document
  // this is why they are separated from the initialValues
  documents?: DocumentItem[]
  initialValues?: FormData
  household?: Household
}

const FarmerDetailsForm: React.FC<Props> = ({
  mode,
  onSubmit,
  progress,
  onCancel,
  onRemove,
  userId,
  documents = [],
  initialValues: {
    status = Status.Inactive,
    farm_status,
    firstName = '',
    lastName = '',
    middleName = '',
    email = '',
    canterraId = '',
    phone = '',
    regions = [],
    countryCode = DEFAULT_COUNTRY,
    farmName,
    selfPickup,
    partnership,
    legal_address,
    delivery_address,
    paper_agreement = PaperAgreementStatus.NotNeeded,
    csua_info = {},
    retailer_regions_ids,
    comment = '',
    additionalPhone = '',
    timezone,
    contact_info = {},
  } = {},
}) => {
  const { t } = useTranslation(['farmer', 'common', 'validation'])
  const getDefaultCountry = useDetectedCountry()
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  const removeProgress = useSelector(FarmerSelectors.removeProgress)
  const [fetchMetaDataProgress, metadata] = useFarmerMetaData(userId || '')
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode)

  const schema = useMemo(() => {
    const partners = Yup.object().shape({
      first_name: Yup.string(),
      last_name: Yup.string(),
    })

    const partnership = Yup.object()
      .shape({
        cheque_payee_name: Yup.string(),
        partners: Yup.array().of(partners),
      })
      .nullable()

    const addressSchema = Yup.object().shape({
      address: Yup.string()
        .required(t('validation:field_required'))
        .nullable(),
      city: Yup.string()
        .required(t('validation:field_required'))
        .nullable(),
      postal_code: Yup.string()
        .required(t('validation:field_required'))
        .nullable(),
      region_id: Yup.string()
        .required(t('validation:field_required'))
        .nullable(),
    })

    return Yup.object().shape({
      status: Yup.mixed().oneOf([Status.Active, Status.Inactive, Status.Deleted]),
      farm_status: Yup.string().nullable(),
      firstName: Yup.string().required(t('validation:field_required')),
      lastName: Yup.string().required(t('validation:field_required')),
      middleName: Yup.string(),
      email: Yup.string().email(t('validation:email_invalid')),
      phone: Yup.string()
        .required(t('validation:field_required'))
        .test('is-valid-number', t('validation:invalid_phone_number'), value => isValidPhoneNumber(value || ''))
        .test('is-valid-es-number', t('validation:invalid_phone_number'), value =>
          isValidSpainPhoneNumber(selectedCountryCode, value),
        ),
      additionalPhone: Yup.string().test('is-valid-number', t('validation:invalid_phone_number'), value =>
        !value || phoneCodes.some(item => '+' + item.phone_prefix === value) ? true : isValidPhoneNumber(value || ''),
      ),
      countryCode: Yup.string().required(),
      partnership,
      legal_address: addressSchema,
      delivery_address: addressSchema,
    })
  }, [t, selectedCountryCode, phoneCodes])

  const needSignDocument = useAction(DocumentActions.needSignRequested)

  const handleSubmit = () => {
    if (!formik.isValid) {
      return
    }
    const { ...common } = formik.values

    if (common.document_id) {
      needSignDocument(userId, common.document_id)
    }

    const newEmail = common.email ? common.email : null
    const newAdditionalPhone =
      !common.additionalPhone || phoneCodes.some(item => '+' + item.phone_prefix === common.additionalPhone)
        ? null
        : common.additionalPhone
    const result: FormData = {
      ...common,
      email: newEmail,
      does_not_have_email: !newEmail,
      additionalPhone: newAdditionalPhone,
    }

    result.regions = []

    result.delivery_address = formik.values.delivery_address
      ? {
          ...formik.values.delivery_address,
          country: formik.values.delivery_address.country || getDefaultCountry,
        }
      : null
    result.legal_address = formik.values.legal_address
      ? {
          ...formik.values.legal_address,
          country: formik.values.legal_address.country || getDefaultCountry,
        }
      : null

    if (email && !result.email) {
      result.paper_agreement = PaperAgreementStatus.Sent
    }

    onSubmit(result)
  }

  const formik = useFormik<FormFields>({
    initialValues: {
      status,
      farm_status,
      firstName,
      lastName,
      middleName,
      canterraId,
      email,
      phone,
      regions,
      countryCode,
      farmName,
      selfPickup,
      legal_address: legal_address || { address: '', city: '', region_id: '' },
      delivery_address: delivery_address || { address: '', city: '', region_id: '' },
      partnership,
      paper_agreement,
      csua_info,
      retailer_regions_ids,
      comment,
      additionalPhone,
      timezone,
      contact_info,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <FormikContext.Provider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Wrapper>
            <Column>
              <SectionContainer>
                <SectionBody>
                  <FarmerForm
                    documents={documents}
                    mode={mode}
                    userId={userId}
                    onCountryCodeChange={value => setSelectedCountryCode(value)}
                  />
                </SectionBody>
              </SectionContainer>
            </Column>
            {isAdmin && mode === 'edit' && (
              <Column>
                {userId && <FarmerOrdersInfo farmerId={userId} />}
                <FarmerMetaCard metadata={metadata} progress={fetchMetaDataProgress} />
                <SectionContainer>
                  <SectionBody>
                    <FarmerNotes farmer_id={userId || ''} />
                  </SectionBody>
                </SectionContainer>
              </Column>
            )}
          </Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            name={'submit-button'}
            filled={true}
            progress={progress}
            disabled={!formik.dirty || progress === Progress.WORK}
            intent={'primary'}
            onClick={formik.submitForm}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
          {isAdmin && mode === 'edit' && (
            <StickyFooterDeleteBtn
              onRemove={onRemove || noop}
              removeProgress={removeProgress}
              popoverText={t('form.removeFarmer', { name: `${firstName} ${lastName}` })}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikContext.Provider>
  )
}

export default FarmerDetailsForm
