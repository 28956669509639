import { Progress } from 'modules/types'
import { Dict } from 'types/generics'
import { ROLES, Status } from 'types/entities'
import { Region } from 'modules/domain/collection/types'
import { FarmerOrderDeliveryAddress, FarmerOrderStatus } from 'types/farmerOrder'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { CountryCode } from 'libphonenumber-js'
import { UserDTO } from 'modules/domain/user/types'
import { SkuOption } from '../storefront/types'
import { PackageTypes } from '../packageTypes/types'

export enum PaperAgreementStatus {
  Needed = 'need_send',
  NotNeeded = 'not_needed',
  Sent = 'sent',
}

export enum FarmStatus {
  NoLongerFarming = 'no_longer_farming',
  DoNotContact = 'do_not_contact',
  DoNotText = 'do_not_text',
  DoNotEmail = 'do_not_email',
  Deceased = 'deceased',
}

export enum ShirtSizes {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
  XXXL = 'XXXL',
}

export const canFarmerHaveNoEmail = (profile: Farmer): boolean => {
  // if user had email, but then it got deleted, the flag might have inconsistent state
  // paper_agreement === not_needed
  if (!profile.email && profile.paper_agreement === PaperAgreementStatus.NotNeeded) {
    return true
  }
  return profile.paper_agreement !== PaperAgreementStatus.NotNeeded
}

export type FarmerListRequestFilter = {
  search?: string
  country?: CountryCode | CountryCode[]
  status?: Status
  paper_agreement?: PaperAgreementStatus
  household_id?: string
  farm_status?: string
}

export type Partner = {
  first_name?: string
  last_name?: string
  phone_number?: string
  farm_name?: string
}

export type Partnership = {
  cheque_payee_name?: string
  partners?: Partner[]
}

export type Farmer = {
  id: string
  prefix: string
  status: Status
  farm_status?: string
  phone_number: string
  email?: string
  first_name: string
  second_name: string
  last_name: string
  full_name?: string
  country: CountryCode
  regions: Region[]
  regions_ids?: string[]
  legal_address?: FarmerOrderDeliveryAddress | null
  delivery_address?: FarmerOrderDeliveryAddress | null
  addresses?: FarmerOrderDeliveryAddress[] | null
  self_pickup?: boolean
  farm_name?: string
  partnership?: Partnership | null
  canterra_id?: string
  paper_agreement?: PaperAgreementStatus
  does_not_have_email?: boolean
  role: ROLES.USER
  household_id?: string
  household_name?: string
  csua_info?: {
    csua?: string
    form?: string
    date?: string
    batch?: string
    company?: string
    company_tsa?: string
    company_lta?: string
  }
  retailer_regions_ids?: string[]
  comment?: string | null
  additional_phone?: string | null
  timezone?: string
  metadata?: FarmerMetaData
  ordersTotalCount?: number
  contact_info?: {
    role?: string
    birthday?: string
    social_media?: string
    shirt_size?: string
    status?: string
    comm_pref?: string
    notes?: string
  }
}

export type FarmerMetaData = {
  created_at: string
  updated_at: string
  obj_id: string
  author: string
  updated_by: string
  updated_by_user: UserDTO
  created_by_user: UserDTO
}

export type FarmerListRequestSorting = {
  sort_field?: keyof Farmer
  sort_reversed?: boolean
}

export type FarmerDTO = Omit<Farmer, 'id' | 'prefix' | 'email' | 'metadata'> & { id?: string; email?: string | null }

export type FarmersState = {
  items: Dict<Farmer>
  meta: Dict<EntityMetadata<Farmer>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  itemMetaDataFetchProgress: Progress
  itemMetaDataFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail: string
  updateProgress: Progress
  updateError: UpdateError | null
  updateErrorDetail: string
  removeProgress: Progress
  removeError: RemoveError | null

  farmersWaitingAgreementCount: number
  farmersWaitingAgreementProgress: Progress

  ordersTotalCountFetchProgress: Progress
  ordersTotalCountFetchError: FetchError | null

  addProductToCartProgress: Progress

  filter: FarmerListRequestFilter
  sorting: FarmerListRequestSorting
  page: number
  total: number
  pageSize: number
}

export type FarmerListResponse = {
  total_count: number
  data: Farmer[]
}

export type FarmerOrderCountSearchBy =
  | 'everything'
  | 'user'
  | 'distributor'
  | 'producer'
  | 'product'
  | 'slug'
  | 'farm_name'
  | 'email'
  | 'household'

export type FarmerOrderCountRequestFilter = {
  owner_id?: string | string[]
  distributor_id?: string | string[]
  producer_id?: string | string[]
  status?: FarmerOrderStatus
}

export type FarmerOrderSkuCountRequestFilter = FarmerOrderCountRequestFilter & {
  sku_id?: string | string[]
}

export type FarmerData = Farmer & {
  ordersCount?: number
}

export type AddToFarmerCartOptions = {
  package_id?: string
  package?: PackageTypes
  options?: SkuOption[]
  options_ids?: string[]
  max_qty?: number
}

export type AddToFarmerCartDTO = {
  farmer_id: string
  product_card_id: string
  quantity: number
  sku_id: string
  added_from_crm_options?: AddToFarmerCartOptions
}
