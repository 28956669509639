import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import NoteActions from 'modules/domain/note/duck'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import * as managers from './managers'
import NoteSelectors from './selectors'
import { Note } from 'modules/domain/note/types'
import NoteRoutes from 'views/pages/Note/routes'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(NoteSelectors.page)
    const filter = yield select(NoteSelectors.filter)
    const sorting = yield select(NoteSelectors.sorting)
    const pageSize = yield select(NoteSelectors.pageSize)

    let response: ListResponse<Note> = yield call(managers.getNotesList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getNotesList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(NoteActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, NoteRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(NoteActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(NoteSelectors.page)
    const filter = yield select(NoteSelectors.filter)
    const sorting = yield select(NoteSelectors.sorting)
    const pageSize = yield select(NoteSelectors.pageSize)
    const { data, total_count }: { data: Note[]; total_count: number } = yield call(
      managers.getNotesList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(NoteActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(NoteActions.listRequestNextFailed(errType))
  }
}

export const fetchNote = function*({ payload: id }: ReturnType<typeof NoteActions.itemRequested>) {
  try {
    const note: Note = yield call(managers.getNote, id)
    yield put(NoteActions.itemRequestSucceed(note))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(NoteActions.itemRequestFailed(id, errType))
  }
}

export const addNote = function*({ payload: dto }: ReturnType<typeof NoteActions.addRequested>) {
  try {
    const note: Note = yield call(managers.addNote, dto)
    yield put(NoteActions.addSucceed(note))

    yield put(push(generatePath(NoteRoutes.Edit, { id: note.id })))
  } catch (e) {
    const errType = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(NoteActions.addFailed(errType, detail))
  }
}

export const updateNote = function*({ payload: [id, dto] }: ReturnType<typeof NoteActions.updateRequested>) {
  try {
    const note: Note = yield call(managers.updateNote, id, dto)
    yield put(NoteActions.updateSucceed(note))
  } catch (e) {
    const type = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(NoteActions.updateFailed(id, type, detail))
  }
}

export const removeNote = function*({ payload }: ReturnType<typeof NoteActions.removeRequested>) {
  try {
    yield call(managers.removeNote, payload)
    yield put(NoteActions.removeSucceed(payload))
    yield put(push(NoteRoutes.List))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(NoteActions.removeFailed(payload, errType))
  }
}

const NoteSaga = function*() {
  yield all([
    takeLatest(NoteActions.itemRequested.type, fetchNote),

    takeLatest(NoteActions.listRequested.type, fetchList),
    takeLatest(NoteActions.filterUpdated.type, fetchList),
    takeLatest(NoteActions.sortingUpdated.type, fetchList),
    takeLatest(NoteActions.filterHasBeenReset.type, fetchList),
    takeLatest(NoteActions.sortingHasBeenReset.type, fetchList),

    takeLatest(NoteActions.listRequestedNext.type, fetchListNext),

    takeLatest(NoteActions.addRequested.type, addNote),
    takeLatest(NoteActions.updateRequested.type, updateNote),
    takeLatest(NoteActions.removeRequested.type, removeNote),
  ])
}

export default NoteSaga
