import React, { useCallback, useMemo, useEffect } from 'react'
import { CellProps, useTable } from 'react-table'
import * as Styled from 'views/pages/Contact/ContactList/styles'
import styled from 'styled-components'
import { Contact, ContactListRequestFilter } from 'modules/domain/contact/types'
import ContactSelectors from 'modules/domain/contact/selectors'
import ContactActions from 'modules/domain/contact/duck'
import { useContactList } from 'modules/domain/contact/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import * as managers from 'modules/domain/contact/managers'
import { ContactRole } from 'modules/domain/contact/types'
import {
  StatusIcon,
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  useDidMount,
} from '@agro-club/frontend-shared'
import GLOBAL from '../../../../global'

const TableStyled = styled(Table)`
  min-width: 354px;
`

const NameCell: React.FC<CellProps<Contact>> = ({ cell, column, row }) => {
  return (
    <Styled.NameCell key={column.id}>
      <Styled.Status status={row.values.status} />
      {`${cell.value || ''} ${row.values.last_name || ''}`}
    </Styled.NameCell>
  )
}

type CompanyContactListProps = {
  company_id_filter: string
}

const NameColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('contact')
  return (
    <div>
      <StatusIcon />
      <span>{t('list.tableHeaders.firstNameLastName')}</span>
    </div>
  )
}

const ContactsSortableHeadCell = AdvancedHeadCell<keyof Contact>()

const CompanyContactList: React.FC<CompanyContactListProps> = ({ company_id_filter }) => {
  GLOBAL.contactEditReturnPath = 'Company'
  const history = useHistory()
  const { t } = useTranslation(['contact', 'labels'])
  const [progress, data] = useContactList()
  const filterUpdated = useAction(ContactActions.filterUpdated)
  const sortingUpdated = useAction(ContactActions.sortingUpdated)
  const listRequested = useAction(ContactActions.listRequested)
  const filterValue = useSelector(ContactSelectors.filter)
  const total = useSelector(ContactSelectors.total)
  const pages = useSelector(ContactSelectors.pages)
  const page = useSelector(ContactSelectors.page)
  const pageSize = useSelector(ContactSelectors.pageSize)
  let { search, company_id } = filterValue
  const { sort_field, sort_reversed } = useSelector(ContactSelectors.sorting)
  let contactRoles: ContactRole[]

  useEffect(() => {
    const fetchContactRoles = async () => {
      try {
        const response = await managers.fetchContactRoles()
        contactRoles = response.data
        console.log('Contact roles:', contactRoles)
      } catch (err) {
        console.error('Error fetching contact roles:', err)
      }
    }

    fetchContactRoles()
  }, [])

  const ContactRolesCell: React.FC<CellProps<Contact, string[]>> = ({ cell, column }) => {
    const roleMap = cell.value.map(roleId => contactRoles.find(role => role.id === roleId)?.details)
    return <div key={column.id}>{roleMap.join(', ') || ''}</div>
  }

  useDidMount(() => {
    handleClearFilters()
    search = undefined
    company_id = company_id_filter
    handleFilterChange({ search, company_id })
  })

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: NameColumnHeaderCell,
        accessor: 'first_name' as const,
        Cell: NameCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.phone'),
        accessor: 'phone' as const,
      },
      {
        Header: t('list.tableHeaders.email'),
        accessor: 'email' as const,
      },
      {
        Header: t('list.tableHeaders.contactRoles'),
        accessor: 'contact_roles_ids' as const,
        Cell: ContactRolesCell,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof Contact)[] = useMemo(() => ['last_name', 'status'], [])

  const columnsAll = useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const { columns, rows, prepareRow } = useTable<Contact>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Contact[],
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<ContactListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('contactList')

  return (
    <TComponents.Wrapper>
      <TableStyled
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={setPage}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <ContactsSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof Contact}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </ContactsSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{t('list.emptyMsg')}</div>
          </TableNoData>
        </TableBody>
      </TableStyled>
    </TComponents.Wrapper>
  )
}

export default CompanyContactList
