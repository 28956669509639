import { Input, useAction } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import useLangPicker from 'hooks/useLangPicker'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import AuthSelectors from 'modules/domain/auth/selectors'
import RetailerTargetSkuActions from 'modules/domain/target2sku/retailerTarget/duck'
import RetailerTargetSkuSelectors from 'modules/domain/target2sku/retailerTarget/selectors'
import { RetailerTargetSku } from 'modules/domain/target2sku/common/types'
import { Progress } from 'modules/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isAgro, isDistributor, isProducer } from 'types/entities'
import { CompoundedTableEditableForm } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditableRow'
import * as Yup from 'yup'
import { FormWrapper } from '../styles'
import { TargetSkuManufacturerSelect } from '../selects/TargetSkuManufacturerSelect'
import { TargetSkuProductSelect } from '../selects/TargetSkuProductSelect'
import { TargetSkuRetailerSelect } from '../selects/TargetSkuRetailerSelect'
import { TargetSkuSubcategorySelect } from '../selects/TargetSkuSubcategorySelect'
import { TargetSkuTerritorySelect } from '../selects/TargetSkuTerritorySelect'

export const RetailerTargetSkuForm: CompoundedTableEditableForm<RetailerTargetSku> = ({ Buttons, entity }) => {
  const { t } = useTranslation(['target2', 'validation'])

  const addRequested = useAction(RetailerTargetSkuActions.addRequested)
  const addProgress = useSelector(RetailerTargetSkuSelectors.addProgress)
  const addErrorDetail = useSelector(RetailerTargetSkuSelectors.addErrorDetail)

  const removeRequested = useAction(RetailerTargetSkuActions.removeRequested)
  const removeProgress = useSelector(RetailerTargetSkuSelectors.removeProgress)
  const removeErrorDetail = useSelector(RetailerTargetSkuSelectors.removeErrorDetail)

  const updateProgress = useSelector(RetailerTargetSkuSelectors.updateProgress)
  const meta = useSelector(RetailerTargetSkuSelectors.meta(entity?.id))

  const updateRequested = useAction(RetailerTargetSkuActions.updateRequested)
  const userCompany = useSelector(AuthSelectors.userCompany)

  const role = useSelector(AuthSelectors.role)

  const producer = isProducer(role)
  const agro = isAgro(role)
  const distributor = isDistributor(role)

  const { pick } = useLangPicker()

  const initialManufacturerId = userCompany
    ? producer
      ? userCompany.id
      : distributor && userCompany.producers_relations.length === 1
      ? userCompany.producers_relations[0]
      : ''
    : ''
  const showManufacturerSelect = initialManufacturerId === ''

  const validationSchema = useMemo(() => {
    return Yup.object({
      territory_id: Yup.string().required(t('validation:field_required')),
      manufacturer_id: Yup.string().required(t('validation:field_required')),
      product_id: Yup.string().required(t('validation:field_required')),
      target_value: Yup.number().required(t('validation:field_required')),
      retailer_id: Yup.string().required(t('validation:field_required')),
    })
  }, [t])

  const initialValues = useMemo(
    () => ({
      territory_id: entity?.territory.id ?? '',
      manufacturer_id: (initialManufacturerId || entity?.manufacturer?.id) ?? '',
      product_id: entity?.product?.id ?? '',
      target_value: entity?.target_value ?? 0,
      retailer_id: entity?.retailer?.id ?? '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entity?.target_value],
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      if (entity?.id) {
        updateRequested(entity.id, {
          target_value: values.target_value,
          territory_id: values.territory_id,
          product_id: values.product_id,
          retailer_id: values.retailer_id,
        })
      } else {
        addRequested(values, false)
      }
    },
  })

  const isEdit = !!entity

  useEffect(() => {
    if (addProgress === Progress.SUCCESS && !entity) {
      formik.resetForm()
      setSubcategoryId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress])

  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity?.target_value])

  const [subcategoryId, setSubcategoryId] = useState<string | undefined>('')

  useValidationErrorNotification(
    formik.submitCount,
    formik.isValid,
    removeErrorDetail || entity ? meta.updateErrorDetail : addErrorDetail,
  )

  const handleChangeManufacturer = val => {
    formik.setFieldValue('manufacturer_id', val)
    formik.setFieldValue('territory_id', '')
    formik.setFieldValue('retailer_id', '')
    formik.setFieldValue('product_id', '')
    setSubcategoryId('')
  }

  return (
    <>
      <FormWrapper>
        {showManufacturerSelect && (
          <TargetSkuManufacturerSelect
            required
            label={t('form.labels.manufacturer')}
            placeholder=""
            isDisabled={isEdit}
            value={formik.values.manufacturer_id}
            valueLabel={entity?.manufacturer?.title}
            onChange={handleChangeManufacturer}
            invalid={!!formik.submitCount && !!formik.errors.manufacturer_id}
          />
        )}
        <TargetSkuTerritorySelect
          required
          isOptionSelected={o => o.id === formik.values.territory_id}
          placeholder={entity?.territory?.title}
          label={t('form.labels.territory')}
          isDisabled={distributor || !formik.values.manufacturer_id}
          value={formik.values.territory_id}
          valueLabel={entity?.territory?.title}
          onChange={val => {
            formik.setFieldValue('territory_id', val)
            formik.setFieldValue('retailer_id', '')
          }}
          invalid={!!formik.submitCount && !!formik.errors.territory_id}
          retailerId={formik.values.retailer_id}
        />
        {(agro || producer) && (
          <TargetSkuRetailerSelect
            required
            label={t('form.labels.retailer')}
            isOptionSelected={o => o.id === formik.values.retailer_id}
            placeholder={formik.values.retailer_id ? entity?.retailer?.title : ''}
            isDisabled={distributor || !formik.values.territory_id}
            value={formik.values.retailer_id}
            valueLabel={entity?.retailer?.title}
            onChange={val => {
              formik.setFieldValue('retailer_id', val)
            }}
            invalid={!!formik.submitCount && !!formik.errors.retailer_id}
            manufacturerId={formik.values.manufacturer_id}
            territoryId={formik.values.territory_id}
          />
        )}
        {!isEdit && (
          <TargetSkuSubcategorySelect
            label={t('form.labels.subcategory')}
            placeholder=""
            value={subcategoryId}
            onChange={setSubcategoryId}
            manufacturerId={formik.values.manufacturer_id}
            isClearable
            isDisabled={!formik.values.manufacturer_id}
          />
        )}
        <TargetSkuProductSelect
          required
          isOptionSelected={o => o.id === formik.values.product_id}
          placeholder={pick(entity?.product?.title)}
          label={t('form.labels.product')}
          isDisabled={distributor || !formik.values.manufacturer_id}
          value={formik.values.product_id}
          valueLabel={pick(entity?.product?.title)}
          onChange={val => {
            formik.setFieldValue('product_id', val)
          }}
          invalid={!!formik.submitCount && !!formik.errors.product_id}
          subcategoryId={subcategoryId}
          manufacturerId={formik.values.manufacturer_id}
        />
        <Input
          required
          label={t('form.labels.target')}
          {...formik.getFieldProps('target_value')}
          invalid={formik.touched.target_value && !!formik.errors.target_value}
          type="number"
          min={0}
        />
      </FormWrapper>
      <Buttons
        onSubmit={formik.handleSubmit}
        onRemove={entity ? () => removeRequested(entity.id) : undefined}
        submitProgress={isEdit ? updateProgress : addProgress}
        removeProgress={removeProgress}
        createText={t('form.create')}
        removeText={t('form.delete')}
        updateText={t('form.update')}
        isSubmitDisabled={!formik.dirty}
      />
    </>
  )
}
