import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/Note/NoteDetailsForm/types'
import * as Styled from 'views/pages/Note/NoteDetailsForm/styled'
import { Input, TextArea, FormComponents } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'
import NoteSelectors from 'modules/domain/note/selectors'
import { Progress } from 'modules/types'

type PropsType = {
  mode: 'edit' | 'create'
}

const NoteForm: React.FC<PropsType> = () => {
  const { t } = useTranslation(['notes', 'common', 'validation', 'noteOrder'])
  const formik = useFormikContext<FormFields>()

  const addErrorDetail = useSelector(NoteSelectors.addErrorDetail)
  const updateErrorDetail = useSelector(NoteSelectors.updateErrorDetail)
  const addProgress = useSelector(NoteSelectors.addProgress)
  const updateProgress = useSelector(NoteSelectors.updateProgress)

  useEffect(() => {
    // eslint-disable-next-line
    if ([addProgress, updateProgress].includes(Progress.ERROR)) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addErrorDetail, updateErrorDetail, addProgress, updateProgress])

  return (
    <>
      <FormComponents.FormSection title={t('note')}>
        <Styled.NoteTextArea>
          <Input
            label={t('noteTitle')}
            {...formik.getFieldProps('note_title')}
            invalid={formik.touched.note_title && !!formik.errors.note_title}
            errorText={formik.errors.note_title}
            required
          />
        </Styled.NoteTextArea>
        <Styled.NoteTextArea>
          <TextArea
            label={t('noteDetails')}
            {...formik.getFieldProps('note_text')}
            invalid={formik.touched.note_text && !!formik.errors.note_text}
            errorText={formik.errors.note_text}
            required
          />
        </Styled.NoteTextArea>
      </FormComponents.FormSection>
    </>
  )
}

export default NoteForm
