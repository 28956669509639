import React, { useCallback } from 'react'
import NoteDetailsForm, { FormData } from './NoteDetailsForm/NoteDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import AuthSelectors from 'modules/domain/auth/selectors'
import NoteActions from 'modules/domain/note/duck'
import NoteSelectors from 'modules/domain/note/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import ContactRoutes from 'views/pages/Contact/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import FarmerRoutes from 'views/pages/Farmer/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { NoteType } from 'types/entities'
import { zip } from 'ramda'
import { useCompanyById } from 'modules/domain/company/hooks'
import { useContact } from 'modules/domain/contact/hooks'
import { useFarmer } from 'modules/domain/farmer/hooks'

const NoteId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const NoteAdd: React.FC = () => {
  const params = useParams<{ note_type: NoteType; parent_id: string }>()
  const profile = useSelector(AuthSelectors.profile)
  const history = useHistory()
  const push = useHistoryPush()
  const page = useSelector(NoteSelectors.page)
  const { t } = useTranslation(['notes', 'common'])
  const noteAddAction = useAction(NoteActions.addRequested)
  console.log('params', params)

  const [contactFetchProgress, contact] = useContact(params.parent_id)
  const [companyFetchProgress, company] = useCompanyById(params.parent_id)
  const [farmerFetchProgress, farmer] = useFarmer(params.parent_id)

  const handleSubmit = useCallback(
    (fields: Required<FormData>) => {
      noteAddAction({
        parent_id: params.parent_id,
        note_type: fields.note_type,
        note_title: fields.note_title,
        note_text: fields.note_text,
        created_by: fields.created_by,
      })
    },
    [noteAddAction],
  )

  const returnRoute = (note_type: NoteType) => {
    switch (note_type) {
      case NoteType.ContactNote:
        return ContactRoutes.Edit
      case NoteType.CompanyNote:
        return CompanyRoutes.Edit
      case NoteType.FarmerNote:
        return FarmerRoutes.Edit
      default:
        return DashboardRoutes.Dashboard
    }
  }

  const goBack = () =>
    params.note_type && params.parent_id
      ? history.push(generatePath(returnRoute(params.note_type), { id: params.parent_id }))
      : push({ route: DashboardRoutes.Dashboard, query: { page } })

  const progress = useSelector(NoteSelectors.addProgress)

  useHelmet({ title: t('addNoteMetaTitle') })

  const getParentTypeDisplayString = (note_type: NoteType) => {
    switch (note_type) {
      case NoteType.ContactNote:
        return zip(
          ['CONTACT'],
          [contactFetchProgress !== Progress.ERROR ? contact?.first_name + ' ' + contact?.last_name : ''],
        )
      case NoteType.CompanyNote:
        return zip(['COMPANY'], [companyFetchProgress !== Progress.ERROR ? company?.official_name : ''])
      case NoteType.FarmerNote:
        return zip(
          ['FARMER'],
          [farmerFetchProgress !== Progress.ERROR ? farmer?.first_name + ' ' + farmer?.last_name : ''],
        )
      default:
        return zip(['PARENT ID'], [params.parent_id])
    }
  }

  const parentIdString = getParentTypeDisplayString(params.note_type)
    .filter(([, id]) => !!id)
    .map(arr => arr.join(': '))
    .join(', ')

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('form.add')} />
          <NoteId>{parentIdString}</NoteId>
          <NoteId>
            {profile?.first_name || ''} {profile?.last_name || ''}
          </NoteId>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <NoteDetailsForm
          onCancel={goBack}
          mode={'create'}
          initialValues={{
            parent_id: params.parent_id,
            note_type: params.note_type,
            created_by: `${profile?.first_name || ''} ${profile?.last_name || ''}`,
          }}
          onSubmit={handleSubmit}
          progress={progress}
        />
      </Layout.Content>
    </>
  )
}

export default NoteAdd
