import { Note, NoteDTO, NoteListRequestFilter, NoteListRequestSorting } from 'modules/domain/note/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'

export const getNotesList = (
  filter: NoteListRequestFilter,
  sorting: NoteListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<Note>>(endpoints.notes(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Notes list fetch error!', err)
    throw err
  }
}

export const getNote = (id: string) => {
  try {
    return apiClient.get<Note>(endpoints.notes(id))
  } catch (err) {
    console.error('Note fetch error!', err)
    throw err
  }
}

export const addNote = (note: NoteDTO) => {
  return apiClient.post<Note>(endpoints.notes(), note)
}

export const updateNote = (id: string, note: Partial<NoteDTO>) => {
  return apiClient.put<Note>(endpoints.notes(id), note)
}

export const removeNote = (id: string) => {
  return apiClient.delete(endpoints.notes(id))
}

export const notesCount = async (filter: NoteListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<Note>>(endpoints.notes(), {
    ...filter,
    page_size: 1,
  })
  return response.total_count
}
