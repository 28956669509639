import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ContactActions from 'modules/domain/contact/duck'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import * as managers from './managers'
import ContactSelectors from './selectors'
import { Contact } from 'modules/domain/contact/types'
import ContactRoutes from 'views/pages/Contact/routes'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ContactSelectors.page)
    const filter = yield select(ContactSelectors.filter)
    const sorting = yield select(ContactSelectors.sorting)
    const pageSize = yield select(ContactSelectors.pageSize)

    let response: ListResponse<Contact> = yield call(managers.getContactsList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getContactsList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ContactActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, ContactRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ContactSelectors.page)
    const filter = yield select(ContactSelectors.filter)
    const sorting = yield select(ContactSelectors.sorting)
    const pageSize = yield select(ContactSelectors.pageSize)
    const { data, total_count }: { data: Contact[]; total_count: number } = yield call(
      managers.getContactsList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ContactActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactActions.listRequestNextFailed(errType))
  }
}

export const fetchContact = function*({ payload: id }: ReturnType<typeof ContactActions.itemRequested>) {
  try {
    const contact: Contact = yield call(managers.getContact, id)
    yield put(ContactActions.itemRequestSucceed(contact))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactActions.itemRequestFailed(id, errType))
  }
}

export const addContact = function*({ payload: dto }: ReturnType<typeof ContactActions.addRequested>) {
  try {
    const contact: Contact = yield call(managers.addContact, dto)
    yield put(ContactActions.addSucceed(contact))

    yield put(push(generatePath(ContactRoutes.Edit, { id: contact.id })))
  } catch (e) {
    const errType = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(ContactActions.addFailed(errType, detail))
  }
}

export const updateContact = function*({ payload: [id, dto] }: ReturnType<typeof ContactActions.updateRequested>) {
  try {
    const contact: Contact = yield call(managers.updateContact, id, dto)
    yield put(ContactActions.updateSucceed(contact))
  } catch (e) {
    const type = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(ContactActions.updateFailed(id, type, detail))
  }
}

export const removeContact = function*({ payload }: ReturnType<typeof ContactActions.removeRequested>) {
  try {
    yield call(managers.removeContact, payload)
    yield put(ContactActions.removeSucceed(payload))
    yield put(push(ContactRoutes.List))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactActions.removeFailed(payload, errType))
  }
}

const ContactsSaga = function*() {
  yield all([
    takeLatest(ContactActions.itemRequested.type, fetchContact),

    takeLatest(ContactActions.listRequested.type, fetchList),
    takeLatest(ContactActions.filterUpdated.type, fetchList),
    takeLatest(ContactActions.sortingUpdated.type, fetchList),
    takeLatest(ContactActions.filterHasBeenReset.type, fetchList),
    takeLatest(ContactActions.sortingHasBeenReset.type, fetchList),

    takeLatest(ContactActions.listRequestedNext.type, fetchListNext),

    takeLatest(ContactActions.addRequested.type, addContact),
    takeLatest(ContactActions.updateRequested.type, updateContact),
    takeLatest(ContactActions.removeRequested.type, removeContact),
  ])
}

export default ContactsSaga
