import {
  Contact,
  ContactDTO,
  ContactListRequestFilter,
  ContactListRequestSorting,
  ContactRole,
  ContactRoleListRequestFilter,
  ContactRoleListRequestSorting,
  CommunicationPreference,
  CommunicationPreferenceListRequestFilter,
  CommunicationPreferenceListRequestSorting,
} from 'modules/domain/contact/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const getContactsList = (
  filter: ContactListRequestFilter,
  sorting: ContactListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<Contact>>(endpoints.contacts(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Contacts list fetch error!', err)
    throw err
  }
}

export const getContact = (id: string) => {
  try {
    return apiClient.get<Contact>(endpoints.contacts(id))
  } catch (err) {
    console.error('Contact fetch error!', err)
    throw err
  }
}

export const addContact = (contact: ContactDTO) => {
  return apiClient.post<Contact>(endpoints.contacts(), contact)
}

export const updateContact = (id: string, contact: Partial<ContactDTO>) => {
  return apiClient.put<Contact>(endpoints.contacts(id), contact)
}

export const removeContact = (id: string) => {
  return apiClient.delete(endpoints.contacts(id))
}

export const contactsCount = async (filter: ContactListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<Contact>>(endpoints.contacts(), {
    ...filter,
    page_size: 1,
  })
  return response.total_count
}

export const fetchContactRoles = (
  filter?: ContactRoleListRequestFilter,
  sorting?: ContactRoleListRequestSorting,
  page?: number,
  pageSize?: number,
) => {
  return apiClient.get<ListResponse<ContactRole>>(endpoints.contactRoles(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getContactRolesByIds = createItemsByIds(true, (ids: string[]) => {
  return apiClient.get<ListResponse<ContactRole>>(endpoints.contactRoles(), {
    ids,
  })
})

export const fetchCommunicationPreferences = (
  filter?: CommunicationPreferenceListRequestFilter,
  sorting?: CommunicationPreferenceListRequestSorting,
  page?: number,
  pageSize?: number,
) => {
  return apiClient.get<ListResponse<CommunicationPreference>>(endpoints.communicationPreferences(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getCommunicationPreferencesByIds = createItemsByIds(true, (ids: string[]) => {
  return apiClient.get<ListResponse<CommunicationPreference>>(endpoints.communicationPreferences(), {
    ids,
  })
})
