import React, { useCallback } from 'react'
import ContactDetailsForm, { FormData } from './ContactDetailsForm/ContactDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useContact } from 'modules/domain/contact/hooks'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import ContactSelectors from 'modules/domain/contact/selectors'
import ContactActions from 'modules/domain/contact/duck'
import Routes from './routes'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import CompanyRoutes from 'views/pages/Company/routes'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { zip } from 'ramda'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import GLOBAL from '../../../global'
import { useCompanyById } from 'modules/domain/company/hooks'

const ContactId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const HeaderInner = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 36px;
`

const ContactEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const push = useHistoryPush()
  const updateContactAction = useAction(ContactActions.updateRequested)
  const removeContactAction = useAction(ContactActions.removeRequested)
  const page = useSelector(ContactSelectors.page)
  const { t } = useTranslation('contact')

  const handleSubmit = useCallback(
    (data: FormData) => {
      updateContactAction(params.id, {
        company_id: data.company_id,
        contact_type: data.contact_type,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        address: data.address,
        department: data.department,
        social_media: data.social_media,
        birthday: data.birthday,
        com_prefs_ids: data.com_prefs_ids,
        contact_roles_ids: data.contact_roles_ids,
        status: data.status,
        slug: data.slug,
      })
    },
    [params.id, updateContactAction],
  )

  const handleRemove = () => {
    removeContactAction(params.id)
  }

  const [fetchProgress, contact] = useContact(params.id)
  const [companyFetchProgress, company] = useCompanyById(contact?.company_id)

  const meta = useSelector(state => ContactSelectors.meta(state, params.id))

  const goBack = () =>
    contact && GLOBAL.contactEditReturnPath === 'Company'
      ? history.push(generatePath(CompanyRoutes.Edit, { id: contact.company_id }))
      : push({ route: Routes.List, query: { page } })

  useHelmet({ title: t('contactsMetaTitle') })

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !contact) {
    return loading()
  }

  const name = [contact.first_name, contact.last_name].filter(Boolean).join(' ')

  const idString = zip(['ID'], [contact.id])
    .filter(([, id]) => !!id)
    .map(arr => arr.join(': '))
    .join(', ')

  let companyIdString = ''
  if (companyFetchProgress !== Progress.ERROR) {
    companyIdString = zip(['COMPANY'], [company?.official_name || ''])
      .filter(([, id]) => !!id)
      .map(arr => arr.join(': '))
      .join(', ')
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <HeaderInner>
            <div>
              <Header.Title size={'small'} compact={true} title={name} />
              <ContactId>{idString}</ContactId>
              <ContactId>{companyIdString}</ContactId>
            </div>
          </HeaderInner>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ContactDetailsForm
          contact_id={contact.id}
          onCancel={goBack}
          onRemove={handleRemove}
          mode={'edit'}
          progress={meta.updateProgress}
          userId={params.id}
          initialValues={{
            company_id: contact.company_id,
            contact_type: contact.contact_type,
            first_name: contact.first_name || '',
            last_name: contact.last_name || '',
            email: contact.email || '',
            phone: contact.phone || '',
            address: contact.address,
            department: contact.department || '',
            social_media: contact.social_media || '',
            birthday: contact.birthday,
            com_prefs_ids: contact.com_prefs_ids,
            contact_roles_ids: contact.contact_roles_ids,
            status: contact.status,
            slug: contact.slug,
          }}
          onSubmit={handleSubmit}
        />
      </Layout.Content>
    </>
  )
}

export default ContactEdit
