import { all, call, fork } from 'redux-saga/effects'

import AuthSaga from './domain/auth/sagas'
import SignupSaga from './domain/signup/sagas'
import UsersSaga from './domain/user/sagas'
import FarmersSaga from './domain/farmer/sagas'
import CompanySaga from './domain/company/sagas'
import ProductSaga from './domain/product/sagas'
import BadgeSaga from './domain/badge/sagas'
import OrderSaga from './domain/farmerOrder/sagas'
import FarmerOrderSkuSaga from './domain/farmerOrderSku/sagas'
import FccOrderSaga from './domain/fccOrder/sagas'
import FccOrderSkuSaga from './domain/fccOrderSku/sagas'

import CollectionSaga from './domain/collection/sagas'
import CategorySaga from './domain/category/sagas'

import UploadManagerSaga from './domain/uploadManager/sagas'
import MailingListSaga from './domain/mailingList/sagas'
import CallBackRequestSaga from './domain/callBackRequest/sagas'
import UptakeSaga from './domain/uptake/sagas'
import TerritorySaga from './domain/territory/sagas'
import ReportSaga from './domain/report/sagas'
import { locationChangeWatcher } from './sagaHelpers'
import DistributorOrderSaga from './domain/distributorOrder/sagas'
import DistributorOrderSkuSaga from './domain/distributorOrderSku/sagas'

import PromocodeSaga from './domain/promocode/sagas'
import PromoInfoSaga from './domain/promoInfo/sagas'
import IncentiveCampaignSaga from './domain/incentiveCampaign/sagas'
import IncentiveProgramSaga from './domain/incentiveProgram/sagas'
import DocumentSaga from './domain/document/sagas'
import HouseholdSaga from './domain/household/sagas'
import ReturnDeclarationSaga from './domain/returnDeclaration/sagas'
import ReturnDeclarationSkuSaga from './domain/returnDeclarationSku/sagas'
import DiscountRuleSaga from './domain/discountRule/sagas'
import TerritoryTargetSaga from './domain/target2/territoryTarget/sagas'
import FarmerTargetSaga from './domain/target2/farmerTarget/sagas'
import RetailerTargetSaga from './domain/target2/retailerTarget/sagas'
import TerritoryTargetSkuSaga from './domain/target2sku/territoryTarget/sagas'
import FarmerTargetSkuSaga from './domain/target2sku/farmerTarget/sagas'
import RetailerTargetSkuSaga from './domain/target2sku/retailerTarget/sagas'
import CropTargetSkuSaga from './domain/target2sku/cropTarget/sagas'
import LicenseSaga from './domain/license/sagas'
import ConfigSaga from './domain/config/sagas'
import SeasonSaga from './domain/season/sagas'
import ReconciliationSaga from './domain/reconciliation/sagas'
import InventoryExchangeSaga from './domain/inventoryExchange/sagas'
import InventoryExchangeTransactionSaga from './domain/inventoryExchangeTransaction/sagas'
import ProductOptionsSaga from './domain/productOptions/sagas'
import StorefrontSaga from './domain/storefront/sagas'
import StorefrontCardSaga from './domain/storefrontCard/sagas'
import PackageTypesSaga from './domain/packageTypes/sagas'
import InventoryInTransferRequestSaga from './domain/inventoryInTransferRequest/sagas'
import InventoryOutTransferRequestSaga from './domain/inventoryOutTransferRequest/sagas'
import NotificationsListSaga from './domain/notificationsList/sagas'
import RetailerAllocationSaga from './domain/allocation/retailerAllocation/sagas'
import ProgramSummarySaga from './domain/programSummary/sagas'
import ModalSaga from './domain/modal/sagas'
import TerritoryAllocationSaga from './domain/allocation/territoryAllocation/sagas'
import ProductsAvailabilitySaga from './domain/productsAvailability/sagas'
import ProductSettingsSaga from './domain/productSettings/sagas'
import SnackbarSaga from './domain/snackbar/sagas'
import StorefrontProductSaga from './domain/storefrontProduct/sagas'
import ChangeLogSaga from './domain/changeLog/sagas'
import CommentsSaga from './domain/comments/sagas'
import ContactSaga from './domain/contact/sagas'
import NoteSaga from './domain/note/sagas'

const rootSaga = function*(): Generator {
  yield all([
    call(AuthSaga),
    call(SignupSaga),
    call(UsersSaga),
    call(FarmersSaga),
    call(CompanySaga),
    call(ProductSaga),
    call(BadgeSaga),
    call(OrderSaga),
    call(FarmerOrderSkuSaga),
    call(FccOrderSaga),
    call(FccOrderSkuSaga),
    call(CollectionSaga),
    call(CategorySaga),
    call(UploadManagerSaga),
    call(MailingListSaga),
    call(CallBackRequestSaga),
    call(UptakeSaga),
    call(DistributorOrderSaga),
    call(DistributorOrderSkuSaga),
    fork(locationChangeWatcher),
    call(TerritorySaga),
    call(ReportSaga),
    call(PromocodeSaga),
    call(PromoInfoSaga),
    call(IncentiveCampaignSaga),
    call(IncentiveProgramSaga),
    call(DocumentSaga),
    call(HouseholdSaga),
    call(ReturnDeclarationSaga),
    call(ReturnDeclarationSkuSaga),
    call(DiscountRuleSaga),
    call(TerritoryTargetSaga),
    call(FarmerTargetSaga),
    call(RetailerTargetSaga),
    call(TerritoryTargetSkuSaga),
    call(FarmerTargetSkuSaga),
    call(RetailerTargetSkuSaga),
    call(CropTargetSkuSaga),
    call(LicenseSaga),
    call(ConfigSaga),
    call(SeasonSaga),
    call(ReconciliationSaga),
    call(InventoryExchangeSaga),
    call(InventoryExchangeTransactionSaga),
    call(InventoryInTransferRequestSaga),
    call(InventoryOutTransferRequestSaga),
    call(ProductOptionsSaga),
    call(StorefrontSaga),
    call(StorefrontCardSaga),
    call(PackageTypesSaga),
    call(NotificationsListSaga),
    call(RetailerAllocationSaga),
    call(TerritoryAllocationSaga),
    call(ProgramSummarySaga),
    call(ChangeLogSaga),
    call(ModalSaga),
    call(SnackbarSaga),
    call(ProductsAvailabilitySaga),
    call(ProductSettingsSaga),
    call(StorefrontProductSaga),
    call(CommentsSaga),
    call(ContactSaga),
    call(NoteSaga),
  ])
}

export default rootSaga
