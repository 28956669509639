import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import DistributorOrderSkuActions from './duck'
import DistributorOrderSkuSelectors from './selectors'
import managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery, mapFilesToDto } from 'modules/sagaHelpers'
import DistributorOrderSkuRoutes from 'views/pages/DistributorOrderSku/routes'
import { push } from 'connected-react-router'
import { RequestError } from 'modules/errors'
import * as uuid from 'uuid'
import { changePersistentFilters, getPersistentFilter } from '../../utils/helpers'
import { DistributorOrderSku } from './types'
import { generatePath } from 'modules/utils/helpers/generatePath'

const prepareOrder = (order: DistributorOrderSku): DistributorOrderSku => {
  order.sku_items.forEach(item => (item.key = uuid.v4()))
  return order
}

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(DistributorOrderSkuSelectors.page)
    const filter = yield select(DistributorOrderSkuSelectors.filter)
    const persistentFilters = getPersistentFilter('season_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }

    yield put(DistributorOrderSkuActions.filtersUpdatedWithPersistentStorage(filterUpdated))
    const sorting = yield select(DistributorOrderSkuSelectors.sorting)
    const pageSize = yield select(DistributorOrderSkuSelectors.pageSize)

    let response: ListResponse<DistributorOrderSku> = yield call(
      managers.getList,
      filterUpdated,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(
      DistributorOrderSkuActions.listRequestSucceed(
        data.map(prepareOrder),
        total_count,
        response.meta.total_qty,
        response.meta.total_final_qty,
        response.meta.total_delivered_qty,
        response.meta.total_standard_qty,
        page,
      ),
    )

    if (currentPage) {
      yield call(updateLocationQuery, DistributorOrderSkuRoutes.List, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.listRequestFailed(errType))
  }
}

export const fetchListUpdate = function*() {
  try {
    const currentPage = yield select(DistributorOrderSkuSelectors.page)
    const filter = yield select(DistributorOrderSkuSelectors.filter)
    const sorting = yield select(DistributorOrderSkuSelectors.sorting)
    const pageSize = yield select(DistributorOrderSkuSelectors.pageSize)
    const response: ListResponse<DistributorOrderSku> = yield call(
      managers.getList,
      filter,
      sorting,
      currentPage || 1,
      pageSize,
    )
    const { data, total_count } = response
    yield put(DistributorOrderSkuActions.listUpdateRequestSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.listUpdateRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(DistributorOrderSkuSelectors.page)
    const filter = yield select(DistributorOrderSkuSelectors.filter)
    const sorting = yield select(DistributorOrderSkuSelectors.sorting)
    const pageSize = yield select(DistributorOrderSkuSelectors.pageSize)
    const { data, total_count }: { data: DistributorOrderSku[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(DistributorOrderSkuActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof DistributorOrderSkuActions.itemRequested>) {
  try {
    const order: DistributorOrderSku = yield call(managers.getItem, id)
    yield put(DistributorOrderSkuActions.itemRequestSucceed(prepareOrder(order)))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({
  payload: [dto, duplicate],
}: ReturnType<typeof DistributorOrderSkuActions.addRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const files = yield mapFilesToDto(filesMeta)
    const order: DistributorOrderSku = yield call(managers.addItem, { ...rest, files })
    yield put(DistributorOrderSkuActions.addSucceed(prepareOrder(order)))
    if (!duplicate) {
      yield put(push(generatePath(DistributorOrderSkuRoutes.Edit, { id: order.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.addFailed(errType))
  }
}
export const updateItem = function*({
  payload: [id, dto, duplicate],
}: ReturnType<typeof DistributorOrderSkuActions.updateRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const files = yield mapFilesToDto(filesMeta)
    const order: DistributorOrderSku = yield call(managers.updateItem, id, { ...rest, files })
    yield put(DistributorOrderSkuActions.updateSucceed(prepareOrder(order)))
    if (duplicate) {
      yield put(push(DistributorOrderSkuRoutes.Add))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof DistributorOrderSkuActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(DistributorOrderSkuActions.removeSucceed(payload))
    yield put(push(DistributorOrderSkuRoutes.List))
    yield put(DistributorOrderSkuActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderSkuActions.removeFailed(payload, errType))
  }
}

const DistributorOrderSkuSaga = function*() {
  yield all([
    takeLatest(DistributorOrderSkuActions.itemRequested.type, fetchItem),
    takeLatest(DistributorOrderSkuActions.listRequested.type, fetchList),
    takeLatest(DistributorOrderSkuActions.filterUpdated.type, fetchList),
    takeLatest(DistributorOrderSkuActions.sortingUpdated.type, fetchList),
    takeLatest(DistributorOrderSkuActions.filterHasBeenReset.type, fetchList),
    takeLatest(DistributorOrderSkuActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DistributorOrderSkuActions.listRequestedNext.type, fetchListNext),
    takeLatest(DistributorOrderSkuActions.listUpdateRequested.type, fetchListUpdate),

    takeLatest(DistributorOrderSkuActions.addRequested.type, addItem),
    takeLatest(DistributorOrderSkuActions.updateRequested.type, updateItem),
    takeLatest(DistributorOrderSkuActions.removeRequested.type, removeItem),

    //fork(newOrdersCountFetcher),
  ])
}

export default DistributorOrderSkuSaga
