import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { EntityMetadata } from 'modules/domain/types'
import { DistributorOrderSku } from './types'

const dict = (state: AppGlobalState) => state.distributorOrderSku.items
const ids = (state: AppGlobalState) => state.distributorOrderSku.ids
const item = (id?: string) => (state: AppGlobalState): DistributorOrderSku | undefined =>
  id ? state.distributorOrderSku.items[id] : undefined
const meta = (id?: string) => (state: AppGlobalState): EntityMetadata<DistributorOrderSku> =>
  id && state.distributorOrderSku.meta[id]
    ? state.distributorOrderSku.meta[id]
    : {
        fetchError: null,
        fetchProgress: Progress.IDLE,
        id,
        removeError: null,
        removeProgress: Progress.IDLE,
        updateError: null,
        updateProgress: Progress.IDLE,
      }
const itemFetchProgress = (state: AppGlobalState) => state.distributorOrderSku.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.distributorOrderSku.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.distributorOrderSku.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.distributorOrderSku.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.distributorOrderSku.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: DistributorOrderSku[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.distributorOrderSku.filter
const sorting = (state: AppGlobalState) => state.distributorOrderSku.sorting
const page = (state: AppGlobalState) => state.distributorOrderSku.page
const total = (state: AppGlobalState) => state.distributorOrderSku.total
const totalQty = (state: AppGlobalState) => state.distributorOrderSku.totalQty
const totalDeliveredQty = (state: AppGlobalState) => state.distributorOrderSku.totalDeliveredQty
const totalStandardQty = (state: AppGlobalState) => state.distributorOrderSku.totalStandardQty
const totalFinalQty = (state: AppGlobalState) => state.distributorOrderSku.totalFinalQty
const updateProgress = (state: AppGlobalState) => state.distributorOrderSku.updateProgress
const removeProgress = (state: AppGlobalState) => state.distributorOrderSku.removeProgress
const addProgress = (state: AppGlobalState) => state.distributorOrderSku.addProgress
const hasNext = (state: AppGlobalState) => state.distributorOrderSku.total > state.distributorOrderSku.ids.length
const pageSize = (state: AppGlobalState) => state.distributorOrderSku.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.distributorOrderSku.total / state.distributorOrderSku.pageSize)
const newOrdersCount = (state: AppGlobalState) => state.distributorOrderSku.newOrdersCount
const newOrdersCountProgress = (state: AppGlobalState) => state.distributorOrderSku.newOrdersCountProgress

const DistributorOrderSkuSelectors = {
  filter,
  sorting,
  page,
  total,
  totalQty,
  totalDeliveredQty,
  totalFinalQty,
  totalStandardQty,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  newOrdersCount,
  newOrdersCountProgress,
}

export default DistributorOrderSkuSelectors
