import React, { useCallback } from 'react'
import CompanyDetailsForm, { FormFields } from './CompanyDetailsForm/CompanyDetailsForm'
import CompanyActions from 'modules/domain/company/duck'
import CompanySelectors from 'modules/domain/company/selectors'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Routes from './routes'
import { CompanyRelation } from 'modules/domain/company/types'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const CompanyAdd: React.FC = () => {
  const { t } = useTranslation(['company', 'common'])
  const companyAddAction = useAction(CompanyActions.addRequested)
  const createProgress = useSelector(CompanySelectors.addProgress)
  const handleSubmit = useCallback(
    (fields: Required<FormFields>, branches?: CompanyRelation[]) => {
      companyAddAction({
        dto: {
          country: fields.country,
          is_active: fields.is_active,
          is_seller: fields.is_seller,
          is_visible: fields.is_visible,
          is_supplier: fields.is_supplier,
          sku_orders: fields.sku_orders,
          rank: fields.rank,
          company_type: fields.companyType,
          official_name: fields.officialName,
          internal_name: fields.internalName,
          account_number: fields.accountNumber,
          slug: fields.slug,
          description: fields.description,
          short_description: fields.shortDescription,
          business_id: fields.iban,
          address: fields.address,
          region_id: fields.region_id,
          city: fields.city,
          postal_code: fields.postal_code,
          bank_details: fields.swift,
          currency: fields.currency,
          regions_ids: fields.regions_ids,
          images: fields.images,
          head_company_relation: null,
          producers_relations: fields.producers_relations,
          territories_ids: fields.territories,
          email: fields.email,
          secondary_email: fields.secondary_email,
          phone_number: fields.phone_number,
          taurus_account_number: fields.taurus_account_number,
          yara_account_number: fields.yara_account_number,
          companyRSA: fields.companyRSA,
          companySSA: fields.companySSA,
          requiredFrom: fields.requiredFrom,
          requiredTo: fields.requiredTo,
          comment: fields.comment,
          legal_address: fields.legal_address,
          physical_address: fields.physical_address,
          contacts: fields.contacts,
        },
        branches,
      })
    },
    [companyAddAction],
  )
  const push = useHistoryPush()
  const page = useSelector(CompanySelectors.page)
  const goBack = () => push({ route: Routes.List, query: { page } })

  useHelmet({ title: t('addCompanyMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CompanyDetailsForm
          company_id={undefined}
          onCancel={goBack}
          onSubmit={handleSubmit}
          progress={createProgress}
          branchCompanies={[]}
        />
      </Layout.Content>
    </>
  )
}

export default CompanyAdd
