import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Contact } from 'modules/domain/contact/types'
import { EntityMetadata } from 'modules/domain/types'

const contactsDict = (state: AppGlobalState) => state.contact.items
const ids = (state: AppGlobalState) => state.contact.ids
const contact = (state: AppGlobalState, id: string): Contact | undefined => state.contact.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Contact> =>
  state.contact.meta[id] ||
  state.contact.meta[Object.values(state.contact.items).find(contact => contact.phone === id)?.id || ''] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const itemFetchProgress = (state: AppGlobalState) => state.contact.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.contact.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.contact.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.contact.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.contact.listFetchError
const contactList = createSelector(contactsDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Contact[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.contact.filter
const sorting = (state: AppGlobalState) => state.contact.sorting
const page = (state: AppGlobalState) => state.contact.page
const total = (state: AppGlobalState) => state.contact.total
const updateProgress = (state: AppGlobalState) => state.contact.updateProgress
const updateError = (state: AppGlobalState) => state.contact.updateError
const updateErrorDetail = (state: AppGlobalState) => state.contact.updateErrorDetail
const removeProgress = (state: AppGlobalState) => state.contact.removeProgress
const addProgress = (state: AppGlobalState) => state.contact.addProgress
const addError = (state: AppGlobalState) => state.contact.addError
const addErrorDetail = (state: AppGlobalState) => state.contact.addErrorDetail
const hasNext = (state: AppGlobalState) => state.contact.total > state.contact.ids.length
const pageSize = (state: AppGlobalState) => state.contact.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.contact.total / state.contact.pageSize)

const ContactSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  addErrorDetail,
  updateError,
  updateErrorDetail,
  contactsDict,
  ids,
  contact,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  contactList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default ContactSelectors
