import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { NoteType } from 'types/entities'
import { AddButton, FormComponents } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import NoteList from '../../Note/NoteList/NoteList'
import NoteRoutes from 'views/pages/Note/routes'

type CompanyNotesProps = {
  company_id: string
}

const CompanyNotes: React.FC<CompanyNotesProps> = ({ company_id }) => {
  const { t } = useTranslation(['company', 'common', 'validation'])

  return (
    <FormComponents.FormSection title={t('company:form.companyNotes')}>
      <AddButton to={generatePath(NoteRoutes.Add, { note_type: NoteType.CompanyNote, parent_id: company_id })} />
      <Layout.Content>
        <NoteList parent_id_filter={company_id} note_type={NoteType.CompanyNote} />
      </Layout.Content>
    </FormComponents.FormSection>
  )
}

export default CompanyNotes
