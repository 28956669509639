import { Entity, EntityState } from 'modules/domain/types'
import { TargetSkuFarmer } from 'views/pages/Target2Sku/components/selects/TargetSkuFarmerSelect'
import { TargetSkuManufacturer } from 'views/pages/Target2Sku/components/selects/TargetSkuManufacturerSelect'
import { TargetSkuProduct } from 'views/pages/Target2Sku/components/selects/TargetSkuProductSelect'
import { TargetSkuRetailer } from 'views/pages/Target2Sku/components/selects/TargetSkuRetailerSelect'
import { TargetSkuSeason } from 'views/pages/Target2Sku/components/selects/TargetSkuSeasonSelect'
import { TargetSkuCategory } from 'views/pages/Target2Sku/components/selects/TargetSkuSubcategorySelect'
import { TargetSkuTerritory } from 'views/pages/Target2Sku/components/selects/TargetSkuTerritorySelect'

export type TerritoryTargetSkuState = EntityState<
  TargetSku<TargetSkuTypes.Territory>,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrdersSku: number
  totalFarmerOrdersSkuPreviousYear: number
  totalRetailerTargets: number
  totalTargetValues: number
}

export type FarmerTargetSkuState = EntityState<
  TargetSku<TargetSkuTypes.Farmer>,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrdersSku: number
  totalFarmerOrdersSkuPreviousYear: number
  totalRetailerTargets: number
  totalTargetValues: number
}

export type RetailerTargetSkuState = EntityState<
  TargetSku<TargetSkuTypes.Retailer>,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrdersSku: number
  totalFarmerOrdersSkuPreviousYear: number
  totalRetailerOrdersSku: number
  totalTargetValues: number
}

export type CropTargetSkuState = EntityState<
  TargetSku<TargetSkuTypes.Crop>,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrdersSku: number
  totalFarmerOrdersSkuPreviousYear: number
  totalRetailerTargets: number
  totalTargetValues: number
}

export enum TargetSkuTypes {
  Territory,
  Farmer,
  Retailer,
  Crop,
}

export type TargetSku<TargetType extends TargetSkuTypes = TargetSkuTypes.Territory> = Entity & {
  manufacturer: TargetSkuManufacturer
  product?: TargetSkuProduct
  territory: TargetSkuTerritory
  target_value: number
  subcategory?: TargetSkuCategory
  season?: TargetSkuSeason

  created_dttm?: string
  updated_dttm?: string
} & (TargetType extends TargetSkuTypes.Territory
    ? {
        farmer_orders_sku_previous_year: number
        retailer_target: number
        farmer_orders_sku: number
      }
    : {}) &
  (TargetType extends TargetSkuTypes.Retailer
    ? {
        farmer_orders_sku_previous_year: number
        retailer_orders_sku: number
        retailer: TargetSkuRetailer
        farmer_orders_sku: number
      }
    : {}) &
  (TargetType extends TargetSkuTypes.Farmer
    ? {
        farmer_orders_sku: number
        farmer: TargetSkuFarmer
        farmer_orders_sku_previous_year: number
        retailer: TargetSkuRetailer
      }
    : {})

export type TerritoryTargetSku = TargetSku<TargetSkuTypes.Territory>
export type FarmerTargetSku = TargetSku<TargetSkuTypes.Farmer>
export type RetailerTargetSku = TargetSku<TargetSkuTypes.Retailer>
export type CropTargetSku = TargetSku<TargetSkuTypes.Crop>

export type TargetSkuListRequestFilter = {
  search?: string
  search_by?: 'territory' | 'farmer' | 'retailer' | 'product' | 'farmer'

  manufacturer_id?: string
  product_id?: string
  retailer_id?: string
  farmer_id?: string
  season_id?: string | string[]
  territory_id?: string
  subcategory_id?: string
}

export type TargetSkuListRequestSorting = {
  sort_field?: keyof TargetSku
  sort_reversed?: boolean
}

export type TargetSkuCreateDTO<TargetType extends TargetSkuTypes = TargetSkuTypes.Territory> = Pick<
  TargetSku<TargetType>,
  'target_value'
> & {
  manufacturer_id: string
  product_id?: string
  territory_id: string
  season_id?: string
  category_id?: string
  id?: string
} & (TargetType extends TargetSkuTypes.Retailer
    ? {
        retailer_id: string
      }
    : {}) &
  (TargetType extends TargetSkuTypes.Farmer
    ? {
        farmer_id: string
      }
    : {})

export type TerritoryTargetSkuCreateDTO = TargetSkuCreateDTO<TargetSkuTypes.Territory>
export type FarmerTargetSkuCreateDTO = TargetSkuCreateDTO<TargetSkuTypes.Farmer>
export type RetailerTargetSkuCreateDTO = TargetSkuCreateDTO<TargetSkuTypes.Retailer>
export type CropTargetSkuCreateDTO = TargetSkuCreateDTO<TargetSkuTypes.Crop>

export type TargetSkuUpdateDTO = {
  target_value: number
  territory_id?: string
  product_id?: string
  retailer_id?: string
  farmer_id?: string
}
