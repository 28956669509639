import styled from 'styled-components'
import { Button, IconBin, Checkbox as CheckboxComponent } from '@agro-club/frontend-shared'

export const ContactPersonalDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'firstname lastname'
    'phone additionalphone'
    'email department'
    'socialmedia birthday'
    'compref contactroles';
`

export const NameArea = styled.div`
  grid-area: firstname;
`
export const LastNameArea = styled.div`
  grid-area: lastname;
`
export const PhoneNumberArea = styled.div`
  grid-area: phone;
`
export const AdditionalPhoneNumberArea = styled.div`
  grid-area: additionalphone;
`
export const EmailArea = styled.div`
  grid-area: email;
`
export const DepartmentArea = styled.div`
  grid-area: department;
`
export const SocialMediaArea = styled.div`
  grid-area: socialmedia;
`
export const BirthdayArea = styled.div`
  grid-area: birthday;
`
export const ComPrefArea = styled.div`
  grid-area: compref;
`
export const ContactRolesArea = styled.div`
  grid-area: contactroles;
`
export const NotesArea = styled.div`
  grid-area: notes;
`

export const PermissionsWrapper = styled.div`
  width: 200px;
`

export const RemoveButton = styled(Button)`
  margin-left: auto;
`

export const RemoveBtn = styled(IconBin)`
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 12px;
  transition: 0.3s fill;

  &:hover {
    fill: ${({ theme }) => theme.color.primary500};
  }
`

export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`

export const EmptyList = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const CommentBlock = styled.div`
  width: 480px;
`

export const EmailSwitchArea = styled.div`
  margin-top: 8px;
`
